import { toKatakanaCase, toZenkakuCase, toZenkakuSpace, toZenkanaCase } from 'encoding-japanese';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAsyncEffect } from "use-async-effect";
import { useLocation } from "react-router-dom";
import { IRootState } from './store/rootModel';
import { Request as TokenRequest } from "./api/TokenLink/Request";
import { Response as TokenResponse } from "./api/TokenLink/Response";
import { Response as NttResponse } from "./api/NttDataResult/Response";
import { Response as TokenLinkValidateResponse } from "./api/TokenLinkValidate/Response";
import { Api06Result } from './api/AuthKofrYoConfirmCustomer/Api06Result';


/** サイドバーが開いた際のサイズ */
export const drawerWidth = 280;
/** サイドバーが閉じた際のサイズ */
export const drawerMinWidth = 56;
/** タイトルバーの高さ */
export const appbarHeight = 60;

/** メールアドレスのバリデーション */
export const mailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**漢字が含まれないバリデーション ??二文字目以降が判定されない */
export const fullNameValidation = /^([^\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][^\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
/** 漢字かどうかを判定する正規表現 */
export const IsKanjiRegx = /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu;

export const materialUiLogo = "M375 476.3l225-129.9V173.2l-75 43.3v86.6l-150 86.6zm150-346.4V43.3L600 0v86.6z";

declare module 'react' {
    type FCX<P = {}> = FunctionComponent<P & { className?: string }>
}
declare module 'react-redux' {
    interface DefaultRootState extends IRootState { }
}

declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        android9dot6Tablet: true;
    }
}

export function Delay(msec: number, lazyAction?: Function) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (lazyAction) {
                try {
                    lazyAction()
                } catch (e) {
                    reject(e);
                }
            }
            setTimeout(() => resolve(null), msec);
        }, 1);
    });
}

var setTimeoutHandle: { [key: string]: any } = {};
export function Lazy(action: Function, msec: number) {
    const key = action.toString();
    clearTimeout(setTimeoutHandle[key]);
    return new Promise((resolve, reject) => {
        setTimeoutHandle[key] = setTimeout(() => {
            resolve(action());
        }, msec);
    });
}

export const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function useScreenOrientation(): OrientationType {
    // eslint-disable-next-line no-restricted-globals
    const getOrientation = () => screen["msOrientation"] || screen["mozOrientation"] || (screen.orientation || {}).type;
    const [orientation, setOrientation] = useState(getOrientation());
    useEffect(() => {
        const handleOrientationChange = () => setOrientation(getOrientation());
        window.addEventListener('orientationchange', handleOrientationChange);
        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, []);
    return orientation;
}

export function ConvertKana(s: string) {
    let newValue = toZenkakuCase(s);
    newValue = toKatakanaCase(newValue);
    newValue = toZenkanaCase(newValue);
    newValue = toZenkakuSpace(newValue);

    return newValue;
}

export async function RequestTokenLink(enc: string) {

    const method = "POST";
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    };
    const request: TokenRequest = {
        token: enc
    }
    const res = await fetch(`api/TokenLink`, {
        method: method,
        headers: headers,
        credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
        body: JSON.stringify(request)//json
    });

    const result: TokenResponse = await res.json();

    if (res.status !== 200) {
        throw new Error(result.errorMessage);
    }

    return result;
}

/** 共通化した */
export async function GetResultAsync(enc: string) {
    const method = "POST";
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    };
    const res = await fetch(`api/NttDataResult?enc=${encodeURIComponent(enc as string)}`, {
        method: method,
        headers: headers,
        credentials: 'include',
    });

    const response: NttResponse = await res.json();
    if (res.status !== 200) {
        console.error(res);
        console.trace(response);
        throw new Error(`${response.errorMessage}：${res.statusText}(${res.status})`);
    }
    return response;
}

export async function ValidateTokenAsync(token: string) {
    const method = "POST";
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    };
    const res = await fetch(`api/TokenLinkValidate?token=${encodeURIComponent(token)}`, {
        method: method,
        headers: headers,
        credentials: 'include',
    });

    const result: TokenLinkValidateResponse = await res.json();

    if (res.status !== 200) {
        console.error(res);
        throw new Error(result.errorMessage);
    }

    if (result.status === Api06Result.KiykshaJotiError) {
        return false;
    }
    return true;
}

export async function RegisterLocation(location: string) {
    const method = "POST";
    const headers = {
        'Accept': 'application/json',
        'content-Type': 'application/json;charset=utf-8'
    };
    try {
        await fetch(`api/RegisterLocation?location=${location}`, {
            method: method,
            headers: headers,
            credentials: 'include',
        });
    }
    catch (error) {
        console.error(error);
    }
}

export function quickHideAddressBar() {
    setTimeout(function () {
        if (window.pageYOffset !== 0) return;
        window.scrollTo(0, window.pageYOffset + 1);
    }, 100);
}

export async function ScrollToTopAsync() {
    await Delay(1);
    window.scrollTo(0, 0);
}
export const UseScrollToTop = (func: Function) => function (force: boolean = false) {
    if (force) {
        window.scrollTo(0, 0);        
    } else {
        func(() => {
            window.scrollTo(0, 0);        
        }, [freez]);
    }
    return null;    
}
const freez: any = 0;