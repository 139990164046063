export enum Api06Result {
    Success = 0,
    /**
     * 認証失敗
     * ※引数が判定条件に合致する対象契約者が存在しない場合
     */
    KiykshaNotFoundError = 1,
    /**
     * 認証失敗
    * ※引数が判定条件に合致する対象契約者が存在したが、契約状態が認証条件に合致しない場合
    */
    KiykshaJotiError = 2,
}