import React from "react";
import { IDevice, ITitle } from "./model";
import { IActionBase } from "../rootModel";



export const UPDATE_TITLE = "UPDATE_TITLE";
interface ITitleType extends ITitle, IActionBase { }
export function UpdateTitle(title?: string, icon?: string): ITitleType {
    return {
        type: UPDATE_TITLE,
        title: title,
        icon: icon,
    };
}


export const UPDATE_DEVICE = "UPDATE_DEVICE";
interface IDeviceType extends IDevice, IActionBase { }
export function UpdateDevice(smartphone: boolean): IDeviceType {
    return {
        type: UPDATE_DEVICE,
        smartphone: smartphone,
    };
}