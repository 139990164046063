import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CssBaseline,
    makeStyles,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { IRootState } from "../../store/rootModel";
import Titlebar from "../Titlebar";
import BottomNav from "../BottomNav";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";

const cssInCode = makeStyles(style);


const Layout: React.FC = (props) => {
    const css = cssInCode();
    const { children, ...rest } = props;
    return (
        <div className={css.root}>
            {/* タイトルバー */}
            <Titlebar className={css.title} />

            {/* メインのコンテンツ */}
            <main id="content-wrapper" className={clsx(css.main /*, "scroll" */)}>
                <div className={css.mainInner} >
                    {children}
                </div>
                <BottomNav />
            </main>
        </div>
    );
};

export default Layout;