import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    contractInquiryInformationGrid: {
        margin: "10px 0 20px 0",
    },
    contractInquiryInformation: {
        marginBottom: "5px",
    },
    contractInquiryInformationCheckIcon: {
        fontSize: "inherit",
        color: "#1cacf4",
        marginRight: "5px",
    },
    contractInquiryErrorMessageCol: {
        minWidth: "230px",
        maxWidth: "1180px",
        marginLeft: "20px",
    },
    contractInquiryErrorMessage: {
        color: "red",
        //textIndent: "1em", 先頭1文字字下げ
    },
    contractInquiryErrorMessageSpace: {
        marginTop: "5px",
    },
    pcContractDetailsContainerMB: {
        marginBottom: "10px",
    },
    radio: {
        '&$checked': {
            color: '#1cacf4'
        },
    },
    radioSpan: {
        /* CSSのセレクターは & スペースで続けて書くと可能  */
        "& > span": {
            fontWeight: "bold"
        }
    },
    checked: {},
    confirmationLink: {
        color: "darkgray",
        marginBottom: "0px",
    },
    inputTel: {
        width: "28%",
        height: "50px",
    },
    checkBoxLabel: {
        fontSize: "5px",
    },
    hyphen: {
        padding: "10px 5px 0 5px",
        color: "#d0d0d0",
        fontWeight: "bolder",
    },
    colorGray: {
        color: "gray",
    },
    moveNavigationGrid: {
        marginTop: "10px",
    },
    btn: {
        padding: "10px",
        width: "95%",
        marginTop: "5px",
        minWidth: "205px",
        color: "white",
        backgroundColor: "#1cacf4",
        fontWeight: "bolder",
    },
    bolder: {
        fontWeight: "bolder",
    },
    display: {
        display: "inline",
    },
    displayNone: {
        display: "none",
    },


});


export default style;