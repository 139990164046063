import React from "react";
import {
    makeStyles,
    styled,
    Grid,
    Stepper,
    Step,
    StepContent,
    StepLabel,
    Typography,
    StepIcon,
    SvgIcon,
} from '@material-ui/core';
import { Collapse } from 'react-collapse';
import { mdiAccountEdit } from '@mdi/js';
/** メール入力画面 */
import MailInputView from "./components/MailInput";
/** メール確認・送信画面 */
import MailVerificationView from "./components/MailVerification";
/** メール認証待ちのタイマーカウント画面 */
import MailAuthenticationView from "./components/MailAuthentication";
/** 契約者情報照会画面 */
import ContractorInquiryView from "./components/ContractorInquiry";
/** 契約者情報照会確認画面 */
import ApplicationCompleteView from "./components/ApplicationComplete";
/** エラー画面 */
import ErrorView from "./components/Error";
/** 中断画面 */
import SuspendView from "./components/Suspend";
/** 認証エラー画面 */
import AuthenticationError from "./components/AuthenticationError";

/** メールアドレス 情報 */
import { MailAddressProps } from "./MailAddressProps";
/** NTTサイト転送 情報 */
import NttSiteView from "./components/NttSiteView";
/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
import { UserStepProps } from "./UserStepProps";

const cssInCode = makeStyles(style);
// じゅりちゃんへ   
//stepアイコンのスタイル
const StyledStepLabel = styled(StepLabel)({
    /** アクティブなタイトルのスタイル */
    "& .MuiStepLabel-active": {
        color: "#1cacf4"
    },
    /** 完了したタイトルのスタイル */
    "& .MuiStepLabel-completed": {
        color: "grey"
    },
    /** アクティブなアイコンのスタイル */
    "& .MuiSvgIcon-root": {
        color: "#1cacf4"
    },
    /** 完了したアイコンのスタイル */
    "& .MuiStepIcon-completed": {
        color: "#1cacf4 !important"
    },
    /** 未完了のアイコンのスタイル */
    "& .MuiStepIcon-root": {
        color: "grey"
    }
});

enum StepStatus {
    Mail = 0,
    ContractorInquiry = 1,
    NTT = 2,
    RESULT = 3
}



const UserStepMobile: React.FCX<UserStepProps> = (props) => {
    const classes = cssInCode();

    function EditIcon() {
        return (<SvgIcon className={clsx(classes.editIconStyle, classes.editIconPathStyle)}>
            <path className={classes.editIconPathStyle} d={mdiAccountEdit}></path>
        </SvgIcon>)
    }

    return (
        <div className={classes.root}>

            {/*白フレーム*/}
            <Grid container className={classes.gridWhite}>

                {/*タイトル*/}
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} >
                        <div className={clsx(classes.titleBar, classes.stepTitle)}>
                            <Typography variant="h5" id="title" className={classes.bolder}>口座振替払いのお申し込み</Typography>
                            <Typography variant="subtitle1" className={classes.bolder}>（ガス料金等・電気料金等）</Typography>
                        </div>
                    </Grid>
                </Grid>

                {/*ステッパー*/}
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stepper
                            activeStep={props.currentStep}
                            orientation="vertical"//縦
                            className={classes.stepper}
                        >
                            {/*ステップ1　メールアドレス入力*/}
                            <Step>
                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.Mail ? EditIcon : StepIcon}
                                >
                                    メールアドレスの入力
                                </StyledStepLabel>

                                <StepContent>
                                    <Collapse isOpened={props.mailStep === 0}>
                                        <MailInputView
                                            Next={() => props.setMailStep(1)}
                                            State={props.mailState}
                                            SetState={x => props.setMailState(x as MailAddressProps)}
                                        />
                                    </Collapse>
                                    <Collapse isOpened={props.mailStep === 1}>
                                        <MailVerificationView
                                            MailState={props.mailState as MailAddressProps}
                                            SetState={x => props.setMailState(x as MailAddressProps)}
                                            Next={() => props.setMailStep(2)}
                                            Back={() => props.setMailStep(0)}
                                        />
                                    </Collapse>
                                    <Collapse isOpened={props.mailStep === 2}>
                                        <MailAuthenticationView
                                            TimeoutSec={props.mailStep === 2 ? props.mailState?.timeoutSec ?? 30 : -1}
                                            Next={() => props.setCurrentStep(StepStatus.ContractorInquiry)}
                                            Timeout={() => props.AuthenticationTimeOut()}
                                            MailState={props.mailState as MailAddressProps}
                                            id={props.id}//スマホのときはmailStateが消えるから
                                            address={props.address}
                                        />
                                    </Collapse>
                                </StepContent>
                            </Step>

                            {/*ステップ2　契約情報照会*/}
                            <Step >

                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.ContractorInquiry ? EditIcon : StepIcon}
                                >
                                    ご契約情報の入力
                                </StyledStepLabel>
                                <StepContent>
                                    <ContractorInquiryView
                                        Next={(contact, ntt) => props.MoveNttSite(contact, ntt)}
                                        id={props.id}
                                        address={props.address}
                                        scrollToTop={props.scrollToStop}
                                    />
                                </StepContent>
                            </Step>

                            {/*ステップ5　申し込み受付*/}
                            <Step >

                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.NTT ? EditIcon : StepIcon}
                                >
                                    口座情報の入力
                                     <Typography variant="caption" component="p">（外部サイト）</Typography>
                                </StyledStepLabel>

                                <StepContent>
                                    <NttSiteView
                                        hash={props.nttState?.hash as string}
                                        url={props.nttState?.url as string}
                                        scrollToTop={props.scrollToStop}
                                    />

                                    {/*    <NttTestView /> */}
                                </StepContent>
                            </Step>

                            {/*ステップ6　WEB申し込みエラー*/}
                            <Step >

                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.RESULT ? EditIcon : StepIcon}
                                >
                                    お申し込み完了
                                </StyledStepLabel>

                                <StepContent className={classes.applicationCompleteViewMargin}>
                                    <Collapse isOpened={props.resultStep === 0}>
                                        <ApplicationCompleteView
                                            Response={props.com}
                                        />
                                    </Collapse>
                                    <Collapse isOpened={props.resultStep === 1}>
                                        <ErrorView
                                            Response={props.com}
                                        />
                                    </Collapse>
                                    <Collapse isOpened={props.resultStep === 2}>
                                        <SuspendView
                                        />
                                    </Collapse>
                                    <Collapse isOpened={props.resultStep === 3}>
                                        <AuthenticationError
                                        />
                                    </Collapse>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
            </Grid >
        </div >
    );
};

export default UserStepMobile;