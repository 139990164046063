import React from "react";
import {
    Hidden,
} from '@material-ui/core';
import { Response as NttResponse } from "../../../../api/NttDataResult/Response";
import PCindex from "./PC/ApplicationCompleteView";
import Mobileindex from "./ApplicationCompleteView";

type Props = {
    Response: NttResponse | undefined;
};

const ApplicationCompleteView: React.FCX<Props> = (props) => {


    return (<>
        <Hidden smDown implementation="css">
            <PCindex
                Response={props.Response}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                Response={props.Response}
            />
        </Hidden>
    </>)
};

export default ApplicationCompleteView;