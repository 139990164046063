import React, { useState, useEffect, Dispatch } from "react";
import {
    useHistory,
} from 'react-router-dom';
import { useAsyncEffect } from "use-async-effect";
import {
    makeStyles,
    Hidden,
} from '@material-ui/core';

import { Request as Req } from "../../api/AuthorizeAccount/Request";
import { Response as Res } from "../../api/AuthorizeAccount/Response";
import { useToast } from "../../core/extensions/SnackbarExtension";

import AuthenticationCompleted from "./indexMobile";
import AuthenticationCompletedPC from "./indexPC";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
import { WaitSite } from "../../store/Overlay/action";
import { useDispatch } from "react-redux";

const cssInCode = makeStyles(style);

const AuthenticationCompletedIndex: React.FC = () => {
    const classes = cssInCode();
    const toast = useToast();
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();

    const [key, setKey] = useState("");
    const [address, setAddress] = useState("");
    const [direct, setDirect] = useState("");

    const [message, setMessage] = useState('認証を行っています...');
    const [message2, setMessage2] = useState('');

    // クエリ文字列を取得する
    useEffect(() => {
        const query = new URLSearchParams(history.location?.search);//自分のクエリを取る

        const _key = query.get('id');
        if (_key) {
            setKey(_key);
        }
        const _address = query.get('address');
        if (_address) {
            setAddress(_address);
        }
        const _direct = query.get('direct');
        if (_direct) {
            setDirect(_direct);
        }
    });

    useAsyncEffect(async () => {
        // くるくる出す
        dispatch(WaitSite(true));
        try {

            await Delay(2 * 1000); //2秒待つ

            if ((!key) || (!address)) return;

            const authResult = await AuthAsync();

            if (!(authResult.isAuth)) {
                history.push(`/authenticationError`);
                setMessage("認証に失敗しました。");
                setMessage2("");
                return;
            }
            if (authResult.timeout) {
                history.push(`/authenticationError?timeout=1`);
                setMessage("タイムアウトしました。");
                setMessage2("");
                return;
            }

            if (direct) {
                setMessage("認証が完了しました。");
                setMessage2("次の画面に遷移します...");
                setTimeout(() => {
                    history.push(`/step?id=${key}&address=${address}`);
                }, 2 * 1000);
            }

        } finally {
            // くるくる出す
            dispatch(WaitSite(false));
        }
    }, [key, address, direct]);


    /**引き数の時間待つ */
    async function Delay(msec: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null);
            }, msec);
        });
    }

    async function AuthAsync() {
        try {
            const method = "POST";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            };
            const request: Req = { key: key as string, address: address as string };
            const res = await fetch(`api/AuthorizeAccount`, {
                method: method,
                headers: headers,
                credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
                body: JSON.stringify(request)//json
            });
            const result: Res = await res.json();
            //異常(status200が正常)
            if (res.status !== 200) {
                //トースターの表示内容
                toast.Error(`${result.ErrorMessage}`);
                return { isAuth: false, timeout: false };//異常はここで終わる
            }
            return { isAuth: result.IsSuccess, timeout: result.Timeout };
        } catch (error) {
            console.error(error);
            toast.Error(error.toString());
            return { isAuth: false, timeout: false };
        }
    }

    return (<>
        { /* smより小さくなった場合に非表示 ≒　PCサイズの場合 */}
        <Hidden smDown implementation="css">
            <AuthenticationCompletedPC
                messageTop={message}
                messageBottom={message2}
            />
        </Hidden>
        { /* mdより大きくなった場合に非表示 ≒　スマフォサイズの場合 */}
        <Hidden mdUp implementation="css">
            <AuthenticationCompleted
                messageTop={message}
                messageBottom={message2}
            />
        </Hidden>
    </>)
}
export default AuthenticationCompletedIndex;