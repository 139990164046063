import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

//css
const style: Styles<Theme, {}> = (theme) => ({
    bottomNav: {
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        height: "auto",
        backgroundColor: "#019fe8",
        paddingTop: "1rem",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        margin: "5px",
    },
    link: {
        color: "white",
    },
    split: {
        marginRight: "1rem",
        marginLeft: "1rem"
    },
    copylight: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
    },
});

export default style;
