import React from "react";
import {
    Hidden, makeStyles,
} from '@material-ui/core';
import { Response as NttResponse } from "../../../../api/NttDataResult/Response";

import PCindex from "./PC/ErrorView";
import Mobileindex from "./ErrorView";


type Props = {
    Response: NttResponse | undefined;//ErrorCDとか
};

const ErrorView: React.FCX<Props> = (props) => {
    return (<>

        <Hidden smDown implementation="css">
            <PCindex
                Response={props.Response}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                Response={props.Response}
            />
        </Hidden>

    </>)
};

export default ErrorView;