import React from "react";
import {
    makeStyles,
    Hidden,
    Grid,
} from '@material-ui/core';

import AuthenticationError from '../Step/components/AuthenticationError';

/** css in js(ts)  */
import clsx from "clsx";
const cssInCode = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        padding: 10,
        minHeight: "100%",
        marginBottom: "5rem",
        marginTop: "1rem",
    },
    gridWhite: {
        backgroundColor: "white",
        padding: "10px 12px 40px 12px",
        borderRadius: "10px",
        //marginTop: "20px",
        maxWidth: "920px",
        //maxWidth: "1245px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    pcRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        minHeight: "100%",
        marginBottom: "6rem",
        marginTop: "1rem",
        width: "100%",
    },
    pcGridWhite: {
        backgroundColor: "white",
        padding: "26px 12px 60px 12px",
        borderRadius: "10px",
        marginTop: "20px",
        width: "95%",
        // maxWidth: "920px",
        maxWidth: "1160px",
        marginRight: "auto",
        marginLeft: "auto",
    },
}));
const AuthenticationErrorIndex: React.FC = () => {
    const classes = cssInCode();

    return (<>
        { /* smより小さくなった場合に非表示 ≒　PCサイズの場合 */}
        <div className={classes.pcRoot}>
            <Grid container className={classes.pcGridWhite}>
                <AuthenticationError />
            </Grid>
        </div>
    </>);
}

export default AuthenticationErrorIndex;