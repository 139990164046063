import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    authenticationErrorViewMessageContainer: {
        margin: "25px 0 20px",
        width: "100%",
    },

    authenticationErrorViewMessageFrame: {
        backgroundColor: "whitesmoke",
        padding: "15px 3px",
        margin: "10px auto",
        width: "100%",
        maxWidth: "420px",
    },
    authenticationErrorViewCenter: {
        textAlign: "center",
        margin: "10px 0 15px 0",
    },
    authenticationErrorViewBolder: {
        fontWeight: "bolder",
    },
    authenticationErrorFrame: {
        border: "2px solid lightgray",
        borderRadius: "4px",
        margin: "0 auto",
        textAlign: "center",
        padding: "12px",
        maxWidth: "420px",
    },
    authenticationErrorReceptionTime: {
        color: "gray",
    },
    authenticationErrorPhoneColor: {
        color: "#1cacf4",
        fontWeight: "bolder",
    },
    telImgHeight: {
        height: "105px",
    },
    telImgCenter: {
        textAlign: "center",
    },
    authenticationErrorViewMessageSpace: {
        marginBottom: "8px",
    },




});


export default style;