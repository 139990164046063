import React from "react";


export interface IArchive {
    processing:number,
}

export const initialArchive: IArchive = {
    processing: 5,
}

