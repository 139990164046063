import React from "react";
import {
    Hidden,
} from '@material-ui/core';

import PCindex from "./PC/SuspendView";
import Mobileindex from "./SuspendView";


type Props = {
};

const SuspendView: React.FCX<Props> = (props) => {
    return (<>
        <Hidden smDown implementation="css">
            <PCindex

            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex

            />
        </Hidden>
    </>)
};

export default SuspendView;