import React from "react";
import { useForm, FormProvider } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import { ContractInfomationProps, NttProps } from "./ContractInfomationProps";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./../../style";
import localStyle from "./ContractorInquiryStyle";
import Telephone from "./../../components/Telephone";
import FullName from "./../../components/FullName";
import CustomerNumber from "./../../components/CustomerNumber";
import StyledButton from "../../../../components/Button/StyledButton";

const cssInCode = makeStyles(style);
const lokcalCssInCode = makeStyles(localStyle);

//index.tsxで設定されている
type Props = {
    Next: (contact: ContractInfomationProps, nttSate: NttProps) => void;
    id: string | null | undefined;
    address: string | null | undefined;
    InquiryAsync: (data: ContractInfomationProps) => Promise<void>;
    radioValue: string;
    clickAgreementRadio: (value: string) => void;
    oksmLabel: {
        oksmNo: string;
        oksmNoPlaceholder: string;
        validation: string;
        oksmName: string;
        noRequiredValidation: string;
        nameRequiredValidation: string;
    };
    href: {
        OksmNoConfirmation: string;
    };
    isKiykshaNotFoundError: boolean;
    contractInquiryBtnLabel: string;
};


const ContractorInquiryView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = lokcalCssInCode();

    const methods = useForm<ContractInfomationProps>();
    const {
        register,
        handleSubmit,
        errors,
        reset,
        getValues,
        setValue
    } = methods;

    return (<FormProvider {...methods}>
        <form onSubmit={handleSubmit(async (data) => await props.InquiryAsync(data))}>

            {/* 説明 */}
            <div className={localClasses.contractInquiryInformationGrid}>

                <Grid container className={localClasses.contractInquiryInformation}>
                    <Grid item xs={1} sm={1} md={1} >
                        <CheckIcon className={localClasses.contractInquiryInformationCheckIcon} />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} >
                        <Typography
                            variant="body2"
                            component="p"
                            className={clsx(localClasses.bolder, classes.underlineStyle)}
                        >
                            以下のご契約情報をご入力ください。
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={localClasses.contractInquiryInformation}>
                    <Grid item xs={1} sm={1} md={1} >
                        <CheckIcon className={localClasses.contractInquiryInformationCheckIcon} />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} >
                        <Typography
                            variant="body2"
                            component="p"
                            className={localClasses.contractInquiryInformation}
                        >
                            西部ガスの都市ガス、電気両方をご契約いただいているお客さまは、両方の支払い方法が変更されます。
                    </Typography>
                    </Grid>
                </Grid>

                <Grid container className={localClasses.contractInquiryInformation}>
                    <Grid item xs={1} sm={1} md={1} >
                        <CheckIcon className={localClasses.contractInquiryInformationCheckIcon} />
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} >
                        <Typography
                            variant="body2"
                            component="p"
                            className={localClasses.contractInquiryInformation}
                        >
                            お手元に「ガスご使用量のおしらせ（検針票）」等をご準備ください。
                    </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className={clsx(localClasses.contractInquiryErrorMessage, props.isKiykshaNotFoundError ? localClasses.display : localClasses.displayNone)}>
                <Grid container>
                    <Grid item>
                        <Typography variant="body2" component="p">
                            入力いただいたお客さま情報が見つかりません。
                        </Typography >
                        <Typography variant="body2" component="p" className={localClasses.contractInquiryErrorMessageSpace}>
                            ご入力内容に誤りがないかお確かめのうえ、画面下部の再入力ボタンからご入力ください。
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.frameP}>
                {/* ご契約内容 */}
                <Grid container className={localClasses.pcContractDetailsContainerMB}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.textFieldLabelDiv}>
                            {/* 
                                    長くなる(120字を超える)タグを1行で書かないこと！！
                                    ESLintの標準は80字で、これを超えると警告、本番はエラーが出ます。

                                    https://garafu.blogspot.com/2017/02/eslint-rules-jp.html
                                    https://qiita.com/sigwyg/items/ce692f41387c709957ef
                                */}
                            <Typography component="span" variant="body2" className={classes.inputLabel}>
                                入力いただくご契約
                            </Typography>
                            <Typography component="span" className={classes.requiredMark}>
                                必須
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <RadioGroup
                            aria-label="ContractDetails"
                            name="ContractDetails"
                            value={props.radioValue}
                            onChange={e => props.clickAgreementRadio(e.target.value)}
                            row
                        >
                            <FormControlLabel
                                control={<Radio color="default" classes={{ root: localClasses.radio, checked: localClasses.checked }} />}
                                label="都市ガス"
                                value="gas"
                                className={clsx(classes.inputLabel, localClasses.radioSpan)}
                                disabled={props.isKiykshaNotFoundError}
                            />

                            <FormControlLabel
                                control={<Radio color="default" classes={{ root: localClasses.radio, checked: localClasses.checked }} />}
                                label="電気"
                                value="electricity"
                                className={clsx(classes.inputLabel, localClasses.radioSpan)}
                                disabled={props.isKiykshaNotFoundError}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>

                {/*                 <Divider className={classes.dividerW100}></Divider>
 */}                <Grid container className={classes.borderStyle}></Grid>

                {/* お客さま番号 */}
                <Grid container>
                    <Grid container>
                        {/* 
                                    長くなる(120字を超える)タグを1行で書かないこと！！
                                    ESLintの標準は80字で、これを超えると警告、本番はエラーが出ます。

                                    https://garafu.blogspot.com/2017/02/eslint-rules-jp.html
                                    https://qiita.com/sigwyg/items/ce692f41387c709957ef
                        */}
                        <Typography component="span" variant="body2" className={classes.inputLabel}
                        >
                            {props.oksmLabel.oksmNo}
                        </Typography>
                        <div>
                            <Typography component="span" variant="caption" className={clsx(classes.inputLabel, props.oksmLabel.oksmName === "電気" && classes.displayNone)}
                            >
                                （料金お問合せ番号）
                            </Typography>
                            <Typography component="span" className={classes.requiredMark}
                            >
                                必須
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <div className={localClasses.confirmationLink}>
                        <Typography variant="caption">
                            ※お客さま番号の確認方法は
                                <Link
                                href={props.href.OksmNoConfirmation}
                                rel="noreferrer" target="_blank"
                                underline="none"
                                className={classes.linkColor}
                            >
                                こちら</Link>
                        </Typography>
                    </div>
                </Grid>

                <Grid container className={classes.inputMB}>
                    <Grid item xs={12} sm={12} md={12}>
                        <CustomerNumber
                            placeHolder={props.oksmLabel.oksmNoPlaceholder}
                            validation={props.oksmLabel.validation}
                            requiredValidation={props.oksmLabel.noRequiredValidation}
                            contract={props.radioValue}
                            disabled={props.isKiykshaNotFoundError}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.borderStyle}></Grid>

                {/* 都市ガスのご契約者さま名 */}
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.textFieldLabelDiv}>
                            {/* 
                                    長くなる(120字を超える)タグを1行で書かないこと！！
                                    ESLintの標準は80字で、これを超えると警告、本番はエラーが出ます。

                                    https://garafu.blogspot.com/2017/02/eslint-rules-jp.html
                                    https://qiita.com/sigwyg/items/ce692f41387c709957ef
                            */}
                            <Typography component="span" variant="body2" className={classes.inputLabel}>
                                {props.oksmLabel.oksmName}のご契約者さま名
                            </Typography>
                            <Typography component="span" className={classes.requiredMark}>
                                必須
                            </Typography>
                            <Typography
                                variant="caption"
                                component="p"
                                className={localClasses.colorGray}>
                                ※カタカナで入力してください
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container className={classes.inputMB}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FullName
                            requiredValidation={props.oksmLabel.nameRequiredValidation}
                            disabled={props.isKiykshaNotFoundError}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.borderStyle}></Grid>

                {/* ju　必須チェック（メッセは1個でいい） */}
                {/* 連絡先電話番号 */}
                <Grid container>
                    <div className={classes.textFieldLabelDiv}>
                        {/* 
                                    長くなる(120字を超える)タグを1行で書かないこと！！
                                    ESLintの標準は80字で、これを超えると警告、本番はエラーが出ます。

                                    https://garafu.blogspot.com/2017/02/eslint-rules-jp.html
                                    https://qiita.com/sigwyg/items/ce692f41387c709957ef
                        */}
                        <Typography component="span" variant="body2" className={classes.inputLabel}
                        >
                            連絡先電話番号
                        </Typography>
                        <Typography component="span" className={classes.requiredMark}
                        >
                            必須
                        </Typography>
                    </div>
                </Grid>

                <Grid container className={classes.inputMB}>
                    <Telephone disabled={props.isKiykshaNotFoundError} />
                </Grid>
            </div>

            {/* 確認ボタン */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.center}>
                    <StyledButton
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={localClasses.btn}
                    >
                        {props.contractInquiryBtnLabel}
                    </StyledButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={clsx(classes.center, localClasses.moveNavigationGrid)}>
                    <Typography variant="caption" className={localClasses.colorGray}>
                        お客さまのご契約の確認が完了すると、
                        <div className={classes.pcLabelDisplay}>外部サイトへ遷移します</div>
                    </Typography>

                </Grid>
            </Grid>
        </form>
    </FormProvider>);
};

export default ContractorInquiryView;