import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        padding: 10,
        minHeight: "100%",
        marginBottom: "5rem",
        marginTop: "1rem",
    },

    /* pc */
    pcInformation: {
        margin: "35px 50px 15px 50px",
        width: "90%",
    },
    pcInputFrame: {
        border: "2px solid #74929b",
        borderRadius: "7px",
        boxShadow: "0px 0px 0pt 0pt",
        minWidth: "230px",
        maxWidth: "1180px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    pcInputTitle: {
        color: "#74929b",
        padding: "30px 30px 0px 30px",
        fontWeight: "bold",
    },
    pcInputContainer: {
        padding: "0 35px 20px 35px",
        width: "100%",
    },
    pcInputLabelMT: {
        marginTop: "30px",
        //marginTop: "7px",
    },

    pcInput: {
        width: "70%",
        maxWidth: "530px",
    },
    pcInputLabel: {
        fontWeight: "bolder",
    },
    pcBtnPosition: {
        textAlign: "center",
    },
    pcBtn: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "205px",
        color: "white",
        backgroundColor: "#1cacf4",
        // fontWeight: "bolder",
    },

    requiredMark: {
        backgroundColor: "#ef4f41",
        borderRadius: "40px",
        color: "white",
        marginLeft: "15px",
        padding: "4px 12px",
        fontSize: "11px",
        display: "inline-block",//改行されないように
    },
    clearStyle: {
        marginRight: -10,
        padding: 0
    },
    hidden: {
        //テーブル（表）の行や列にこの値を指定すると、その部分を詰めて表示します。
        visibility: "collapse"
    },
    linkColor: {
        color: "#1cacf4",
    },
    borderStyle: {
        border: "1px dashed #DDDDDD",
        opacity: 1,
        margin: "0 auto 15px auto",
        width: "96%",
    },
    pcApplicationResultTitleContainer: {
        marginTop: "50px",
        textAlign: "center",
    },
    pcApplicationResultTitle: {
        fontWeight: "bolder",
        color: "red",
    },
    pcApplicationResultMessageContainer: {
        margin: "35px 0 30px",
        width: "100%",
        textAlign: "center",
    },
    titleImgHeight: {
        height: "30px",
    },
    editIconStyle: {
        background: "#1cacf4",
        height: "35px",
        width: "35px",
        borderRadius: "50%",
        padding: "7px",
        marginLeft: "-5px"
    },
    editIconPathStyle: {
        color: "white",
    },
    w100: {
        width: "100%",
    },
    displayNone: {
        display: "none",
    },
    underlineStyle: {
        textDecorationColor: "#fff389",
        textDecorationLine: "underline",
        textDecorationThickness: "3px",
        "-webkit-text-decoration": "underline 3px #fff389",
    },


});


export default style;
