import React, { Dispatch, useEffect } from "react";
import { FormProvider, useForm } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import { MailAddressProps } from "./../../MailAddressProps";
import StyledButton from "../../../../components/Button/StyledButton";
import MailAddress from "./../../components/MailAddress";

/* default（主）だと｛ ｝がいらない */

import style from "./../../style";
import localStyle from "./MailInputStyle";
import { UseScrollToTop } from "../../../../global";
const cssInCode = makeStyles(style);
const localCssInCode = makeStyles(localStyle);

//htmlの属性として利用する場合はこのような形で宣言する
type Props = {
    Next: (MailAddressProps) => void;
}

const MailInputView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssInCode();
    
    const methods = useForm<MailAddressProps>();
    const {
        register: register,//input/select の Ref とバリデーションルールを React Hook Form に登録 (register) することができます。
        handleSubmit: submit,//バリデーションに成功するとフォームデータを返します。
        errors: errors,//各inputのエラー、エラーメッセージが含まれています。
        reset: reset,//フィールド値とエラーをデフォルト値でリセットします。
        getValues: getValues,
        setValue: setValue,
    } = methods;

    return (<FormProvider {...methods}>

        <form onSubmit={submit(data => props.Next(data))}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.stepContent}>
                    <Typography variant="body2" component="p">
                        メールアドレスを入力して「確認画面へ」ボタンを押してください。
                        </Typography>
                    <Typography variant="body2" component="p">
                        お申し込みフォームのURLが載ったメールを送信いたします。そちらからお申し込みページにお進みください。
                        </Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <MailAddress
                        label="メール アドレス"
                        path="address"
                        required="入力してください"
                        alignment="right"
                    />
                </Grid>
            </Grid>

            <Grid container className={localClasses.border}></Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <MailAddress
                        label="再入力"
                        path="address2"
                        required="入力してください"
                        alignment="right"
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.btnPosition}>
                    <StyledButton
                        variant="contained"
                        type="submit"
                        className={classes.btn}
                        color="primary"
                    >
                        確認画面へ
                    </StyledButton>
                </Grid>
            </Grid>
        </form>
    </FormProvider >);
};

export default MailInputView;