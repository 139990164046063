import React, { useEffect, useState } from "react";
import {
    useHistory,
} from 'react-router-dom';
import {
    makeStyles,
    Hidden,
} from '@material-ui/core';
import PCindex from "./PC/AuthenticationError";
import Mobileindex from "./AuthenticationError";

import style from "../../style";

type Props = {

};

const cssInCode = makeStyles((theme) => ({
    w100: {
        width: "100%",
    },
}));

const AuthenticationError: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const history = useHistory();
    const [errorMessage1, setErrorMessage1] = useState("・口座振替払申込対象外のご契約です");
    const [errorMessage2, setErrorMessage2] = useState("・ガスまたは電気がご使用中の状態ではありません");
    const [errorMessage1PC, setErrorMessage1PC] = useState("・口座振替払申込対象外のご契約です");
    const [errorMessage2PC, setErrorMessage2PC] = useState("・ガスまたは電気がご使用中の状態ではありません");

    const [visible, setVisible] = useState(true);
    const [href, setHref] = useState("http://www.saibugas.co.jp/home/");
    const [caption, setCaption] = useState("閉じる");

    useEffect(() => {
        const query = new URLSearchParams(history.location?.search);//自分のクエリを取る
        const timeout = query.get('timeout');
        const error = query.get('e');
        if (timeout) {
            setErrorMessage1("30分経過でタイムアウトが発生しました。");
            setErrorMessage2("お手数ですが再度お手続きください。");
            setErrorMessage1PC("メール送信から30分が経過してタイムアウトしました。");
            setErrorMessage2PC("お手数ですが再度お手続きください。");
            setVisible(false);
            setCaption("戻る");
            setHref("/");
        }
        if (error) {
            setErrorMessage1(decodeURIComponent(error));
            setErrorMessage2("");
            setErrorMessage1PC(decodeURIComponent(error));
            setErrorMessage2PC("")
        }
    }, []);


    return (
        <div className={classes.w100}>
            <Hidden smDown implementation="css">
                <PCindex
                    errorMessage1={errorMessage1PC}
                    errorMessage2={errorMessage2PC}
                    visible={visible}
                    href={href}
                    btnCaption={caption}
                />
            </Hidden>

            <Hidden mdUp implementation="css">
                <Mobileindex
                    errorMessage1={errorMessage1}
                    errorMessage2={errorMessage2}
                    visible={visible}
                    href={href}
                    btnCaption={caption}
                />
            </Hidden>
        </div>)
};

export default AuthenticationError;