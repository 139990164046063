import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

//css
const style: Styles<Theme, {}> = (theme) => ({
    root: {
        margin: "50px 0",
    },
    center: {
        textAlign: "center",
    },
    mAuto: {
        margin: "2px auto",
    },
    label: {
        backgroundColor: "white",
        padding: "2px 0 0 15px ",
    },
    value: {
        backgroundColor: "white",
        padding: "0 5px 2px 45px ",
    },
    pcList: {
        backgroundColor: "white",
        padding: "7px ",
    },
    listContainer: {
        width: "40%",
        minWidth: "336px",
    },
    button: {
        padding: "5px",
        margin: "20px 7px",
        color: "gray",
        width: "8%",
        minWidth: "87px",
    },
    colorGray: {
        color: "gray",
    },
    noLineBreak: {
        display: "inline-block",
    },
    titleLine: {
        color: "gray",
        width: "40%",
        minWidth: "336px",
        fontSize: "24px",
        margin: "0 auto 10px auto",
    },
    block: {
        width: "40%",
        minWidth: "186px",
        wordWrap: "break-word",
    },
});

export default style;
