import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import {
    drawerWidth,
    drawerMinWidth,
    appbarHeight,
    transition
} from '../../global';

//css
const style: Styles<Theme, {}> = (theme) => ({
    root: {

    },
    title: {
        marginBottom: "60px",

    },
    main: {
        width: "100%",
        //paddingTop: "60px",
        //position: "absolute",
        overflowY: "auto",
        overflowX: "hidden",
        overflowScrolling: "auto",
        WebkitOverflowScrolling: "auto",
        height: "100%",
        backgroundColor: "#dfecf2",
    },
    mainInner: {
        paddingLeft: "5px",
        paddingRight: "5px",
        minHeight: "calc(100vh - 150px)"
    }
    , displayNone: {
        display: "none",
    }
});

export default style;
