import React, { Dispatch, useEffect, useState } from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import clsx from "clsx";

const cssInCode = makeStyles(theme => ({
    root: {

    },
    form: {
        opacity: 0,
    },
    w100: {
        width: "100%",
    },
    marginL: {
        marginLeft: "10px",
    },
    nowrap: {
        display: "inline-block",
    },
    center: {
        width: "100%",
        textAlign: "center",
    },
}));

const NttSiteViewPC: React.FCX = () => {
    const classes = cssInCode();
    return (<>
        <Grid container className={classes.w100}>
            <Grid item className={classes.center}>
                <Typography variant="body1" component="p" className={clsx(classes.w100)}>
                    外部サイトへ<div className={classes.nowrap}>遷移します</div>
                </Typography>
                <Typography variant="body1" component="p">
                    そのままお待ちください
                 </Typography>
            </Grid>
        </Grid>
    </>)
}

export default NttSiteViewPC;