import React from 'react';
import { Route, useHistory } from 'react-router';
import { BrowserRouter as Router, Switch  } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';

import { useSelector } from 'react-redux';
import { IRootState } from './store/rootModel';

import Home from "./views/Home/index";
import NotSupport from './views/NotSupport/index';
import NttTest from './views/NttTest/index';
import MyPageTest from './views/MyPageTest/index';
import NotFound from './views/NotFound/index';
import history from './history';

import UserStep from './views/Step/index';

import {
    makeStyles,
    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import { isMobile, isIE } from 'react-device-detect';
import AuthenticationCompletedIndex from './views/AuthenticationCompleted';
import AuthenticationErrorIndex from './views/AuthenticationErrorIndex';

/** css in js(ts)  */
const cssInCode = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const AppRoute: React.FC = () => {
    const css = cssInCode();
    const overlay = useSelector((x: IRootState) => x.overlay);
    const his = useHistory();
    function IEPolyfill(Index: React.FC) {
        // IEだとこの画面以外には遷移させない
        if (isIE) {
            return <NotSupport />
        }
        return <Index />
    }

    return (
        <div>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/'>
                        {IEPolyfill(Home)}
                    </Route>
                    <Route exact path='/step'>
                        {IEPolyfill(UserStep)}
                    </Route>
                    <Route exact path='/authenticationcompleted'>
                        {IEPolyfill(AuthenticationCompletedIndex)}
                    </Route>
                    <Route exact path='/authenticationerror'>
                        {IEPolyfill(AuthenticationErrorIndex)}
                    </Route>
                    <Route exact path='/notsupport'>
                        <NotSupport />
                    </Route>
                    <Route exact path='/ntttest'>
                        {IEPolyfill(NttTest)}
                    </Route>
                    <Route exact path='/mypagetest'>
                        <MyPageTest />
                    </Route>

                    { /* URL違う場合は基本的にhomeへ飛ばす */}
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </ConnectedRouter>
            <Backdrop className={css.backdrop} open={overlay.openWaitingContent} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default AppRoute;