import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import { AuthenticationCompletedProps } from "./AuthenticationCompletedProps";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
const cssInCode = makeStyles(style);

const AuthenticationCompleted: React.FCX<AuthenticationCompletedProps> = (props) => {
    const classes = cssInCode();
    return (
        <div className={classes.root}>
            <Grid container className={classes.gridWhite}>
                <div className={classes.titleBar}>
                    <Typography variant="h5" className={classes.bolder}>メールアドレス認証完了</Typography>
                </div>
                <div className={classes.message}>
                    <Typography variant="h6" className={classes.messageSpace}>{props.messageTop}</Typography>
                    <Typography variant="h6">{props.messageBottom}</Typography>
                </div>
            </Grid>
        </div >
    );
};


export default AuthenticationCompleted; 