import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import { Response as NttResponse } from "../../../../api/NttDataResult/Response";
import SubTitleImg from "../../img/ApplicationReceptionTitle.png";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../style";
import localStyle from "./ApplicationCompleteStyle";
const cssInCode = makeStyles(style);
const localCssIncode = makeStyles(localStyle);


type Props = {
    Response: NttResponse | undefined;
};

const ApplicationCompleteView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssIncode();
    return (<>

        <Grid container className={classes.applicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" className={localClasses.applicationCompleteTitle}>
                    ご利用ありがとうございました
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={localClasses.applicationCompleteSubTitleGrid}>
                <img src={SubTitleImg} className={localClasses.subTitleImgHeight}></img>
            </Grid>
        </Grid>

        <Grid container className={localClasses.applicationCompleteFrame}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" className={localClasses.applicationCompleteLabel}>お申し込み内容</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={localClasses.applicationCompleteContentFrame}>
                <Typography className={localClasses.applicationCompleteContent}>口座振替払い</Typography>
            </Grid>
        </Grid>

        <Grid container className={localClasses.applicationCompleteFrame}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" className={localClasses.applicationCompleteLabel}>お客さま番号</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={localClasses.applicationCompleteContentFrame}>
                <Typography className={localClasses.applicationCompleteContent}
                >
                    {props?.Response?.oksmNo}
                </Typography>
            </Grid>
        </Grid>

        <Grid container className={localClasses.applicationCompleteFrame}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" className={localClasses.applicationCompleteLabel}
                >
                    受付番号（Web）
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={localClasses.applicationCompleteContentFrame}>
                <Typography className={localClasses.applicationCompleteContent}
                >
                    {props?.Response?.uktkNo}
                </Typography>
            </Grid>
        </Grid>

        <Grid container className={localClasses.applicationCompleteFrame}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" className={localClasses.applicationCompleteLabel}
                >
                    受付日時（Web）
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={localClasses.applicationCompleteContentFrame}>
                <Typography className={localClasses.applicationCompleteContent}
                >
                    {props?.Response?.UktkTrkNcj}
                </Typography>
            </Grid>
        </Grid>

        <Grid container className={localClasses.applicationCompleteSentenseContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" className={clsx(localClasses.applicationCompleteSentenseSpase, localClasses.leftAlign)}>
                    ※お客さま番号・受付番号（Web）はお問い合わせの際に必要となります。お手元にお控えください。
                    </Typography>
                <div className={clsx(localClasses.applicationCompleteSentenseSpase, localClasses.leftAlign)}>
                    <Typography variant="body2" >※口座振替払い登録のお手続きは２～３日ほどで完了致します。（土日祝除く）また、口座振替払いの開始は、登録完了後の次回または次々回のご請求からとなります。</Typography>
                </div>
                <Typography variant="body2" className={clsx(localClasses.applicationCompleteSentenseSpase, localClasses.leftAlign)}>※お手続き完了までのお支払いは、従来の支払い方法での支払いとなります。</Typography>
            </Grid>
        </Grid>
    </>);
};

export default ApplicationCompleteView;