import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import StyledButton from "../../../../components/Button/StyledButton";

import TitleImg from "../../img/AuthenticationTitle.png";
import TelImg from "../../img/CustomerServiceTel.png";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../style";
import localStyle from "./AuthenticationErrorStyle";
const cssInCode = makeStyles(style);
const localCssIncode = makeStyles(localStyle);

type Props = {
    errorMessage1: string;
    errorMessage2: string;
    visible: boolean;
    href: string;
    btnCaption: string;
};

const AuthenticationError: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssIncode();


    return (<>

        {/* 説明 */}
        <Grid container className={classes.applicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <img src={TitleImg} className={classes.titleImgHeight}></img>
            </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container className={localClasses.authenticationErrorViewMessageContainer}>
            <Grid item xs={12} sm={12} md={12} className={localClasses.authenticationErrorViewCenter}>
                <Typography variant="body2">
                    以下の理由からお申込できません。
                    </Typography>
            </Grid>
        </Grid>

        <Grid container className={localClasses.authenticationErrorViewMessageFrame}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" className={localClasses.authenticationErrorViewMessageSpace}>
                    {props.errorMessage1}
                </Typography>
                <Typography variant="body2">
                    {props.errorMessage2}
                </Typography>
            </Grid>
        </Grid>

        {/* ボタン */}
        <Grid container className={classes.btnPosition}>
            <Grid item xs={12} sm={12} md={12}>
                <StyledButton
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    href={props.href}
                >
                    {props.btnCaption}
                </StyledButton>
            </Grid>
        </Grid>
    </>);
};

export default AuthenticationError;