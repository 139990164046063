import React, { Dispatch } from "react";
import {
  makeStyles,
  styled,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { MailAddressProps } from "../../../MailAddressProps";
import StyledButton from "../../../../../components/Button/StyledButton";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../../PC/style";
import localStyle from "./MailVerificationStyle";
const cssInCode = makeStyles(style);
const localCssInCode = makeStyles(localStyle);

const BackButton = styled(Button)({
  /** アクティブなタイトルのスタイル */
  "&:hover": {
    backgroundColor: "white",
  },
});

type Props = {
  MailState: MailAddressProps;
  Back: Function;
  AuthorizeAsync: () => Promise<void>;
};
const MailVerificationView: React.FCX<Props> = (props) => {
  const classes = cssInCode();
  const localClasses = localCssInCode();

  return (
    <>
      {/* 説明 */}
      <Grid container className={classes.pcInformation}>
        <Typography variant="body2">
          以下のメールアドレスに「口座振替払いのお申し込みメール」をお送りします。
        </Typography>
      </Grid>
      {/* アドレス表示欄 */}
      <Grid container className={localClasses.pcMailverificationsFrame}>
        <Grid item xs={12} sm={12} md={9}>
          <Typography
            className={localClasses.pcMailverifications}
            variant="body2"
          >
            {props?.MailState?.address ?? "xxxxxx@saibugas-group.com"}
          </Typography>
        </Grid>
      </Grid>

      {/* 注意事項 */}
      <Grid
        container
        className={clsx(classes.pcInformation, localClasses.colorGray)}
      >
        <Typography variant="body2" component="p" className={classes.w100}>
          「口座振替払いのお申し込みメール」は、「@saibugas-group.com」ドメインよりお送りいたします。
        </Typography>
        <Typography variant="body2" component="p" className={classes.w100}>
          迷惑メール対策などの設定を行っている場合は、ご確認をお願いいたします。
        </Typography>
      </Grid>

      {/* ボタン */}
      <Grid container className={clsx(classes.pcBtnPosition)}>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            className={classes.pcBtn}
            onClick={async () => await props.AuthorizeAsync()}
          >
            送信
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <BackButton
            variant="contained"
            color="primary"
            className={localClasses.pcBackBtn}
            onClick={() => props.Back()}
          >
            入力画面へ戻る
          </BackButton>
        </Grid>
      </Grid>
    </>
  );
};

export default MailVerificationView;
