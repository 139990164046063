import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Hidden
} from '@material-ui/core';
import { Request } from "../../api/NttTestFunc/Request"
import { Response } from "../../api/NttTestFunc/Response"
import { WaitSite } from "../../store/Overlay/action";
import { useToast } from "../../core/extensions/SnackbarExtension";
import { useHistory } from "react-router";
import NttTestMobile from "./indexMobile";
import NttTestPC from "./indexPC";
const NttTest: React.FC = () => {
    const history = useHistory();

    const dispatch: Dispatch<any> = useDispatch();
    const toast = useToast();

    //エラーコード
    const [errorCD, setErrorCD] = useState("");

    //クエリ文字列から取得
    const [oksmNo,setOksmNo] = useState("");
    const [kaishCD, setKaishCD] = useState("");
    const [storageOrganizationNo, setStorageOrganizationNo] = useState("");
    const [hash, setHash] = useState("");
    const [storageOrganizationYMD, setStorageOrganizationYMD] = useState("");
    const [storageOrganizationTime, setStorageOrganizationTime] = useState("");
    const [oksmNm, setOksmNm] = useState("");
    const [oksmNmState, setOksmnmState] = useState("");

    useEffect(() => {
        const query = new URLSearchParams(history.location?.search);//自分のクエリ 
        setOksmNo(query.get('oksmNo') as string);
        setKaishCD(query.get('kaishCD') as string);
        setStorageOrganizationNo(query.get('storageOrganizationNo') as string);
        setHash(query.get('hash') as string);
        setStorageOrganizationYMD(query.get('storageOrganizationYMD') as string);
        setStorageOrganizationTime(query.get('storageOrganizationTime') as string);
        setOksmNm(query.get('oksmNm') as string);
    }, [])
    useEffect(() => {

        if (oksmNm?.trim()) {
            setOksmnmState(oksmNm);
        }
        else {
            setOksmnmState("ｻｲﾌﾞｼﾞﾛｳ");
        }

    }, [oksmNm]);

    //URIをエンコードするfunction
    async function TestAsync(status: string) {
        //くるくる開始
        dispatch(WaitSite(true));
        try {
            const method = "POST";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8'
            };
            const request: Request = {
                Status: status,
                ErrorCD: errorCD,
                OksmNo: oksmNo as string,
                StorageOrganizationNo: storageOrganizationNo as string,
                StorageOrganizationYMD: storageOrganizationYMD as string,
                StorageOrganizationTime: storageOrganizationTime as string,
                OksmNm: oksmNmState as string,
            };
            const res = await fetch('api/NttTestFunc', {
                method: method,
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(request)
            });
            const response: Response = await res.json();//le・ga・cy、お客さまNoとかが入ってる

            //Reactの画面遷移　push画面が一個進む
            history.push(`/step?enc=${response.enc}`);//遷移（le・ga・cyでお申し込み結果画面が分かれる）
        }
        catch (error) {
            console.error(error);
            toast.Error(error.toString());
        }
        finally {
            //くるくる終了
            dispatch(WaitSite(false));
        }
    }

    return (<>
        { /* smより小さくなった場合に非表示 ≒　PCサイズの場合 */}
        <Hidden smDown implementation="css">
            <NttTestPC
                oksmNmState={oksmNmState}
                setOksmNmState={value => setOksmnmState(value)}

                oksmNo={oksmNo}
                kaishCD={kaishCD}
                storageOrganizationNo={storageOrganizationNo}
                storageOrganizationYMD={storageOrganizationYMD}
                storageOrganizationTime={storageOrganizationTime}
                hash={hash}

                errorCD={errorCD}
                setErrorCD={value => setErrorCD(value)}
            
                TestAsync={TestAsync}
            />
        </Hidden>
        { /* mdより大きくなった場合に非表示 ≒　スマフォサイズの場合 */}
        <Hidden mdUp implementation="css">
            <NttTestMobile
                oksmNmState={oksmNmState}
                setOksmNmState={value => setOksmnmState(value)}

                oksmNo={oksmNo}
                kaishCD={kaishCD}
                storageOrganizationNo={storageOrganizationNo}
                storageOrganizationYMD={storageOrganizationYMD}
                storageOrganizationTime={storageOrganizationTime}
                hash={hash}

                errorCD={errorCD}
                setErrorCD={value => setErrorCD(value)}
            
                TestAsync={TestAsync}                
            />
        </Hidden>           
    </>);
}

export default NttTest