import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import ErrorCD from "../../../../components/ErrorCD/index";
import { Response as NttResponse } from "../../../../api/NttDataResult/Response";
import TitleImg from "../../img/ErrorTitle.png";

import style from "../../style";
import localStyle from "./ErrorStyle";
const cssInCode = makeStyles(style);
const localCssInCode = makeStyles(localStyle);

type Props = {
    Response: NttResponse | undefined;//ErrorCDとか
};

const ErrorView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssInCode();

    return (<>
        {/* 説明 */}
        <Grid container className={classes.applicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <img src={TitleImg} className={classes.titleImgHeight}></img>
            </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container className={classes.applicationResultMessageContainer}>
            <Grid item xs={12} sm={12} md={12}>

                <ErrorCD
                    errorCD={props?.Response?.errorMessage}
                />

                <Typography variant="caption">
                    エラーコード：{props?.Response?.errorMessage}
                </Typography>
            </Grid>
        </Grid>

    </>)
};

export default ErrorView;