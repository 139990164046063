import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    //pcStep2Frame
    pcMailverificationsFrame: {
        width: "92%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "6px",
        backgroundColor: "whitesmoke",
    },
    pcMailverifications: {
        borderRadius: "0px 4px 4px 0",
        padding: "14px 30px",
        fontWeight: "bolder",
    },
    colorGray: {
        color: "darkgray",
    },
    pcBackBtn: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "205px",
        color: "#1cacf4",
        backgroundColor: "white",
        border: "2px solid #1cacf4",
    },

});


export default style;