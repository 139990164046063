import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    TextField
} from '@material-ui/core';
import { NttTestProps } from "./NttTestProps";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
const cssInCode = makeStyles(style);
const NttTestMobile: React.FCX<NttTestProps> = (props) => {
    const css = cssInCode();

    return (
        <div className={css.root}>

            <Grid container className={css.titleLine}>
                NTT TEST
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        お客さま名
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <TextField value={props.oksmNmState} onChange={(e) => props.setOksmNmState(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        お客さま番号
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography>
                        {props.oksmNo}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        会社CD
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography>
                        {props.kaishCD}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        収納機関
                        <div className={css.noLineBreak}>取り扱いNo</div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography>
                        {props.storageOrganizationNo}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        収納機関
                        <div className={css.noLineBreak}>取り扱い年月日</div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography>
                        {props.storageOrganizationYMD}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        収納機関
                        <div className={css.noLineBreak}>取り扱い時刻</div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography>
                        {props.storageOrganizationTime}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        ハッシュ
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <Typography className={css.block}>
                        {props.hash}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        エラーコード
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <TextField value={props.errorCD} onChange={e => props.setErrorCD(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={css.center}>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Success")}
                    >
                        Success
                </Button>

                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Faild")}
                    >
                        Faild
                </Button>

                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Suspends")}
                    >
                        Suspends
                </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default NttTestMobile;