import React from "react";
import CustomerNumberPC from "./CustomerNumberPC";

export type CustomerNumberProps = {
    placeHolder: string;
    validation: string;
    requiredValidation: string;
    contract: string | "gas" | "electric";
    disabled: boolean;
}
function CustomerNumber(props: CustomerNumberProps) {
    return (<>
        <CustomerNumberPC {...props} />
    </>);
}
export default CustomerNumber;