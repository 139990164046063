import React from "react";
import { useFormContext } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Hidden,
} from "@material-ui/core";
import MailAddressPC, { MailAddressComponentProps } from "./MailAddressPC";

function MailAddress(props: MailAddressComponentProps) {
    return (<>
        <MailAddressPC
            {...props}
        />
    </>);
}

export default MailAddress;