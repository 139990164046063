import React, { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    TextField
} from '@material-ui/core';

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
import { StringParam, useQueryParam } from "use-query-params";
import { WaitSite } from "../../store/Overlay/action";
import { Toast } from "reactstrap";
import { useToast } from "../../core/extensions/SnackbarExtension";
import { useHistory } from "react-router";
import { IRootState } from "../../store/rootModel";

import { Request } from "../../api/MyPageTestFunc/Request";
import { Response } from "../../api/MyPageTestFunc/Response";
const cssInCode = makeStyles(style);


const MyPageTest: React.FC = () => {
    const css = cssInCode();
    const overlay = useSelector((x: IRootState) => x.overlay);

    const [oksmNo, setOksmNo] = useState("");
    const [mailAddress, setMailAddress] = useState("");
    const toast = useToast();

    const history = useHistory();

    const dispatch: Dispatch<any> = useDispatch();

    async function TestAsync(timeout: boolean) {

        dispatch(WaitSite(true));
        try {
            const method = "POST";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8'
            };

            const request: Request = {
                GasShriKiykNo: oksmNo,
                Address: mailAddress,
                Timeout: timeout,
            };
            const res = await fetch('api/MyPageTestFunc', {
                method: method,
                headers: headers,
                credentials: 'include',
                body: JSON.stringify(request)
            });

            const response: Response = await res.json();

            history.push(`/step?token=${encodeURIComponent(response.token)}`);

        }
        catch (error) {
            console.error(error);
            toast.Error(error.toString());
        }
        finally {
            dispatch(WaitSite(false));
        }
    }

    return (
        <div className={css.root}>

            <Grid container className={css.titleLine}>
                MyPage TEST
            </Grid>



            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        ガス支払い契約番号
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <TextField value={oksmNo} onChange={e => setOksmNo(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={12} sm={12} md={12} className={css.label}>
                    <Typography variant="caption">
                        メールアドレス
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={css.value}>
                    <TextField value={mailAddress} onChange={e => setMailAddress(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={css.center}>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await TestAsync(true)}

                    >
                        Success
                </Button>

                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await TestAsync(false)}
                    >
                        Faild
                </Button>

                </Grid>
            </Grid>
        </div>
    );
};

export default MyPageTest;