import React, { useState, useEffect } from "react";
import { useFormContext } from 'react-hook-form' //useFormを使用するためインポート
import {
    Grid,
    FormControl,
    InputLabel,
    Input,
    OutlinedInput,
    InputAdornment,
    IconButton,
    InputProps,
    FormHelperText,
    Typography,
    TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { v4 as uuidv4 } from 'uuid';

import { ContractInfomationProps } from "../ContractorInquiry/ContractInfomationProps";
import "../../../../core/extensions/StringExttension";

import { makeStyles } from '@material-ui/core/styles';
import { TelepthonProps } from ".";
const cssInCode = makeStyles(theme => ({
    root: {

    },
    tel: {
        width: "30%",
    },
    hyphen: {
        width: "5%",
        padding: "10px 0px 0 0px",
        textAlign: "center",
        color: "#d0d0d0",
        fontWeight: "bolder",
    },
    errorMessage: {
        height: "15px",
        paddingBottom: "7px",
        marginBottom: "5px"
    },
    disabledColor: {
        backgroundColor: "lightgray !important",
    },
}));
function TelephonePC(props: TelepthonProps) {
    const classes = cssInCode();
    const { register, errors, setValue, getValues } = useFormContext<ContractInfomationProps>();
    const id = `tel-${uuidv4()}`;
    const pos = props?.helperPosition ?? "top";

    function error(tel1?: string, tel2?: string, tel3?: string) {
        if (!String.IsNullOrWhiteSpace(tel1)) {
            return tel1;
        }
        if (!String.IsNullOrWhiteSpace(tel2)) {
            return tel2;
        }
        if (!String.IsNullOrWhiteSpace(tel3)) {
            return tel3;
        }
        return "";
    }

    function hasError() {
        return !String.IsNullOrWhiteSpace(errors.tel1?.message) ||
            !String.IsNullOrWhiteSpace(errors.tel2?.message) ||
            !String.IsNullOrWhiteSpace(errors.tel3?.message);
    }

    function isValidTelepthone(path: string, value: string) {
        const target = {
            tel1: getValues("tel1"),
            tel2: getValues("tel2"),
            tel3: getValues("tel3"),
        }
        target[path] = value;

        const tel = `${target.tel1}${target.tel2}${target.tel3}`;
        if (tel.length < 10 || 11 < tel.length) {
            return "10～11桁の半角数字で入力してください。";
        }
        return true;
    }

    function onChangeTel1(s: string) {
        if (!s) {
            return;
        }
        if (s.length <= 5) {
            return;
        }
        setValue("tel1", s.substring(0, 5));
    }

    function onChangeTel2(s: string) {
        if (!s) {
            return;
        }
        if (s.length <= 4) {
            return;
        }
        setValue("tel2", s.substring(0, 4));
    }

    function onChangeTel3(s: string) {
        if (!s) {
            return;
        }
        if (s.length <= 4) {
            return;
        }
        setValue("tel3", s.substring(0, 4));
    }

    return (
        <div>
            {/* エラーメッセージが二行になる　 */}
            <FormHelperText error={hasError()} className={classes.errorMessage}>
                {error(errors.tel1?.message, errors.tel2?.message, errors.tel3?.message)}
            </FormHelperText>

            <Grid container>
                <Grid item className={clsx(classes.tel)}>
                    <TextField
                        className={clsx(props.disabled ? classes.disabledColor : false)}
                        variant="outlined"
                        placeholder="000"
                        size="small"
                        type="number"
                        name="tel1"
                        autoComplete="number"
                        error={Boolean(errors.tel1)}
                        onChange={x => onChangeTel1(x.target.value)}
                        disabled={props.disabled}
                        inputRef={register({
                            maxLength: {
                                value: 5,
                                message: "10～11桁の半角数字で入力してください。",
                            },
                            required: "10～11桁の半角数字で入力してください。",
                            validate: x => isValidTelepthone("tel1", x)
                        })}
                    >
                    </TextField>
                </Grid>
                <Grid item className={clsx(classes.hyphen)}>
                    <Typography
                        variant="body2"
                        component="span">
                        -
                    </Typography>
                </Grid>
                <Grid item className={clsx(classes.tel)}>
                    <TextField
                        className={clsx(props.disabled ? classes.disabledColor : false)}
                        variant="outlined"
                        placeholder="0000"
                        size="small"
                        type="number"
                        name="tel2"
                        autoComplete="number"
                        error={Boolean(errors.tel2)}
                        onChange={x => onChangeTel2(x.target.value)}
                        disabled={props.disabled}
                        inputRef={register({
                            maxLength: {
                                value: 4,
                                message: "10～11桁の半角数字で入力してください。",
                            },
                            required: "10～11桁の半角数字で入力してください。",
                            validate: x => isValidTelepthone("tel2", x)
                        })}
                    >
                    </TextField>
                </Grid>
                <Grid item className={clsx(classes.hyphen)}>
                    <Typography
                        variant="body2"
                        component="span">
                        -
                    </Typography>
                </Grid>
                <Grid item className={clsx(classes.tel)}>
                    <TextField
                        className={clsx(props.disabled ? classes.disabledColor : false)}
                        variant="outlined"
                        placeholder="0000"
                        size="small"
                        type="number"
                        name="tel3"
                        autoComplete="number"
                        error={Boolean(errors.tel3)}
                        onChange={x => onChangeTel3(x.target.value)}
                        disabled={props.disabled}
                        inputRef={register({
                            maxLength: {
                                value: 4,
                                message: "10～11桁の半角数字で入力してください。",
                            },
                            required: "10～11桁の半角数字で入力してください。",
                            validate: x => isValidTelepthone("tel3", x)
                        })}
                    >
                    </TextField>
                </Grid>
            </Grid>
        </div >
    );
}
export default TelephonePC;