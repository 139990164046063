import React from "react";
import TelephonePC from "./TelephonePC";



export type TelepthonProps = {
    helperPosition?: 'top' | 'bottom', name?: string;
    disabled: boolean;
}

function Telepthon(props: TelepthonProps) {
    return (
        <TelephonePC {...props} />
    );
}

export default Telepthon;