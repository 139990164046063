import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    mailAuthenticationTitleContainer: {
        marginTop: "25px",
        textAlign: "center",
    },
    mailAuthenticationTitle: {
        fontWeight: "bolder",
        color: "#74929b",
    },
    mailAuthenticationMessageContainer: {
        margin: "25px 0 10px 0",
        width: "100%",
        paddingRight: "10px",
    },
    remainingTime: {
        color: "#1cacf4",
        marginTop: "8px",
        padding: "10px 5px",
    },
    remainingTimeColorRed: {
        color: "red",
    },
    remainingTimeFrame: {
        backgroundColor: "whitesmoke",
        borderRadius: "4px",
        padding: "20px 0",
        textAlign: "center",
    },
    rimainingTimeSentense: {
        fontSize: "12px",
    },
    remainingTimeFramePaddind: {
        paddingRight: "10px",
    },
    remainingTimeBolder: {
        fontWeight: "bolder",
    },
    titleImgHeight: {
        width: "50%",
        marginLeft: "-30px"
    },
    displayNone: {
        display: "none",
    },

});


export default style;