import React from "react";
import { ITitle, initialTitle, IDevice, initialDevice } from "./model";
import { IActionBase } from "../rootModel";
import { UPDATE_DEVICE, UPDATE_TITLE } from "./action";

export function titleReducer(
    state: ITitle = initialTitle,
    action: IActionBase): ITitle {

    switch (action.type) {
        case UPDATE_TITLE:
            return {
                ...state,
                title: action.title,
                icon: action.icon
            };
        default:
            return state;
    }
}

export function deviceReducer(
    state: IDevice = initialDevice,
    action: IActionBase): IDevice {

    switch (action.type) {
        case UPDATE_DEVICE:
            return {
                ...state,
                smartphone: action.smartphone
            };
        default:
            return state;
    }
}
