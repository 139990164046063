import React, { useState, useEffect, Dispatch } from "react";
import {
    Hidden,
} from '@material-ui/core';
import { useHistory } from "react-router";
import { RegisterLocation, ValidateTokenAsync } from "../../global";
import HomePC from "./indexPC";
import HomeMobile from "./indexMobile";
import { useDispatch } from "react-redux";
import { WaitSite } from "../../store/Overlay/action";

const Home: React.FCX = () => {
    //くるくる
    const dispatch: Dispatch<any> = useDispatch();
    const href = {
        ListOfFinancialInstitutions: "/financial.jpg",
        CustomerNumberConfirmation: "https://mypage.saibugas.co.jp/home/creca/num_confirm.htm",
        PrivacyPolicy: "http://www.saibugas.co.jp/policy/kojin_hogo.htm",
    };
    const [agreeCheck, setAgreeCheck] = useState(false);
    const history = useHistory();

    function CheckedAgree() {
        setAgreeCheck(!agreeCheck);
    }

    async function onAgreeAsync() {
        const query = new URLSearchParams(history.location?.search);
        const token = query.get('token');

        if (!token) {
            history.push("/step");
            return;
        }

        // くるくる出す
        dispatch(WaitSite(true));
        try {
            if (!(await ValidateTokenAsync(token))) {
                history.push(`./authenticationerror`);
                return;
            }
        } catch (error) {
            console.error(error);
            const msg = decodeURIComponent(error.toString()).replace(/Error:/g, "");;
            history.push(`./authenticationerror?e=${encodeURIComponent(msg)}`);
            return;
        } finally {
            // くるくる出す
            dispatch(WaitSite(false));
        }

        history.push(`/step?agree=1&token=${token}`);
    }

    useEffect(() => {
        const query = new URLSearchParams(history.location?.search);//自分のクエリ 
        const location = query.get('location');

        RegisterLocation(location as string);

    }, [])


    return (<>
        { /* smより小さくなった場合に非表示 ≒　PCサイズの場合 */}
        <Hidden smDown implementation="css">
            <HomePC
                CustomerNumberConfirmation={href.CustomerNumberConfirmation}
                ListOfFinancialInstitutions={href.ListOfFinancialInstitutions}
                PrivacyPolicy={href.PrivacyPolicy}
                agreeCheck={agreeCheck}
                onCheckedAgree={() => CheckedAgree()}
                onAgree={async () => await onAgreeAsync()}
            />
        </Hidden>
        { /* mdより大きくなった場合に非表示 ≒　スマフォサイズの場合 */}
        <Hidden mdUp implementation="css">
            <HomeMobile
                CustomerNumberConfirmation={href.CustomerNumberConfirmation}
                ListOfFinancialInstitutions={href.ListOfFinancialInstitutions}
                PrivacyPolicy={href.PrivacyPolicy}
                agreeCheck={agreeCheck}
                onCheckedAgree={() => CheckedAgree()}
                onAgree={async () => await onAgreeAsync()}
            />
        </Hidden>
    </>)
};
export default Home;