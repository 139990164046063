import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import { MailAddressProps } from "../../MailAddressProps";
import TitleImg from "../../img/SendCompleteTitle.png";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../style";
import localStyle from "./MailAuthenticationStyle";
const cssInCode = makeStyles(style);
const lokcalCssInCode = makeStyles(localStyle);

type Props = {
    TimeoutSec: number;
    Next: Function;
    Timeout: Function;
    MailState: MailAddressProps;
    id?: string | null;
    address?: string | null;
    rimainingTimeOut: boolean;
    rimainingTime: string;
    rimainingMessage: string;
}
const MailAuthenticationView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = lokcalCssInCode();

    return (<>
        {/* タイトル */}
        <Grid container
            className={localClasses.mailAuthenticationTitleContainer}
        >
            <Grid item xs={12} sm={12} md={12}>
                <img src={TitleImg} className={localClasses.titleImgHeight}></img>
            </Grid>
        </Grid>
        {/* 説明 */}
        <Grid container className={localClasses.mailAuthenticationMessageContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2">
                    ご入力いただいたメールアドレスに、「口座振替払いのお申し込みメール」を送信しました。
                </Typography>
                <Typography variant="body2">
                    受信したメールに記載されているURLより、引き続きお申し込み手続きをお願いいたします。
                </Typography>
            </Grid>
        </Grid>

        <Grid container className={clsx(localClasses.remainingTimeFramePaddind, localClasses.displayNone)}>
            <Grid item xs={12} sm={12} md={12}>
                <div className={localClasses.remainingTimeFrame}>
                    <Typography className={localClasses.rimainingTimeSentense}>
                        メール受信から、
                    </Typography>
                    <Typography className={localClasses.rimainingTimeSentense}>
                        30分以内にお手続きください。
                    </Typography>
                    <Typography
                        variant="body1"
                        className={clsx(localClasses.remainingTime, props.rimainingTimeOut && localClasses.remainingTimeColorRed)}
                        component="span"
                    >
                        残り時間
                    </Typography>
                    <Typography
                        variant="h6"
                        className={clsx(localClasses.remainingTime, props.rimainingTimeOut && localClasses.remainingTimeColorRed, localClasses.remainingTimeBolder)}
                        component="span"
                    >
                        {props.rimainingTime}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={clsx(localClasses.remainingTime, props.rimainingTimeOut && localClasses.remainingTimeColorRed)}
                        component="span"
                    >
                        分
                    </Typography>
                    <Typography
                        className={clsx(localClasses.remainingTime, props.rimainingTimeOut && localClasses.remainingTimeColorRed)}
                        component="p"
                        variant="h6"
                    >
                        {props.rimainingMessage}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    </>)
}
export default MailAuthenticationView;