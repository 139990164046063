import React, { Dispatch, useState } from "react";
import { useForm } from 'react-hook-form' //useFormを使用するためインポート
import {
    Hidden,
} from '@material-ui/core';

import { WaitSite } from "../../../../store/Overlay/action";
import { ContractInfomationProps, NttProps } from "./ContractInfomationProps";

import { useToast } from "../../../../core/extensions/SnackbarExtension";
import { Request as Req } from "../../../../api/AuthKofrYoConfirmCustomer/Request";
import { Response as Res } from "../../../../api/AuthKofrYoConfirmCustomer/Response";
import { Api06Result } from "../../../../api/AuthKofrYoConfirmCustomer/Api06Result"
import { Request as HashRequest } from "../../../../api/MakeNttData/Request";
import { Response as HashResponse } from "../../../../api/MakeNttData/Response";
import { useDispatch } from "react-redux";
import { ConvertKana } from "../../../../global";
import { useHistory } from "react-router";

import PCindex from "./PC/ContractorInquiryView";
import Mobileindex from "./ContractorInquiryView";


//index.tsxで設定されている
type Props = {
    Next: (contact: ContractInfomationProps, nttSate: NttProps) => void;
    id: string | null | undefined;
    address: string | null | undefined;
    scrollToTop?: Function;
};

const ContractorInquiry: React.FCX<Props> = (props) => {

    const toast = useToast();
    const href = {
        OksmNoConfirmation: "https://mypage.saibugas.co.jp/home/creca/num_confirm.htm",
    };
    const dispatch: Dispatch<any> = useDispatch();
    const methods = useForm<ContractInfomationProps>();
    const {
        register,
        handleSubmit,
        errors,
        reset,
        getValues,
        setValue
    } = methods;

    //契約ラジオ
    const [radioValue, setRadioValue] = React.useState('gas');
    // ガス/電気でラベルを切り替える
    const [oksmLabel, setOksmLabel] = useState({
        oksmNo: "お客さま番号",
        oksmNoPlaceholder: "9000000001",
        validation: "9から始まる10桁の数字でご入力ください",
        oksmName: "都市ガス",
        noRequiredValidation: "お客さま番号を入力してください。",
        nameRequiredValidation: "ご契約者さま名を入力してください。",
    });

    const history = useHistory();

    const [isKiykshaNotFoundError, setIsKiykshaNotFoundError] = useState(false);

    const [contractInquiryBtnLabel, setContractInquiryBtnLabel] = useState("次へ");

    //契約内容ラジオ
    const clickAgreementRadio = (value) => {
        /**      
         *setは画面レンダリングノ時に反映されるので
         *setRadioValue(value);
         *if(radioValue=="gas")にしても、まだradioValueが更新されていないのでずれる
         **/
        setRadioValue(value);
        if (value == "gas") {
            setOksmLabel({
                oksmNo: "お客さま番号",
                oksmNoPlaceholder: "9000000001",
                validation: "9から始まる10桁の数字でご入力ください",
                oksmName: "都市ガス",
                noRequiredValidation: "お客さま番号を入力してください。",
                nameRequiredValidation: "ご契約者さま名を入力してください。",
            });
        }
        else {
            setOksmLabel({
                oksmNo: "電気お客さま番号",
                oksmNoPlaceholder: "0000000001",
                validation: "0から始まる10桁の数字でご入力ください",
                oksmName: "電気",
                noRequiredValidation: "電気お客さま番号を入力してください。",
                nameRequiredValidation: "ご契約者さま名を入力してください。",
            });
        }
    };

    /* 照会 */
    async function InquiryAsync(data: ContractInfomationProps) {

        //isKiykshaNotFoundErrorがtrueでここに来るときは再入力の場合
        if (isKiykshaNotFoundError) {
            setIsKiykshaNotFoundError(false);
            setContractInquiryBtnLabel("次へ");
            if (props.scrollToTop) {
                props.scrollToTop();
            }
            return;
        }

        // くるくる出す
        dispatch(WaitSite(true));

        try {
            if (!(await AuthAsync(data))) {
                if (props.scrollToTop) {
                    props.scrollToTop();
                }
                return;
            }
            const nttData = await MakeHashAsync(data);
            props?.Next(data, nttData);

        } catch (error) {
            console.error(error);
            toast.Error(decodeURIComponent(error.toString()));
        } finally {
            // くるくる消す
            dispatch(WaitSite(false));
        }
    }

    /**
     * WEB認証基盤に認証をかける
     * @param data 
     */
    async function AuthAsync(data: ContractInfomationProps) {
        const method = "POST";
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        };
        const kana = ConvertKana(data.ContractorName);
        const request: Req = {
            KiykshaNMKana: kana,
            OkyakusamaNO: data.CustomerNumber,
            TelNoKiti1: data.tel1,
            TelNoKiti2: data.tel2,
            TelNoKiti3: data.tel3,
        };
        const res = await fetch(`api/AuthKofrYoConfirmCustomer`, {
            method: method,
            headers: headers,
            credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
            body: JSON.stringify(request)//json
        });
        const result: Res = await res.json();
        //異常(status200が正常)
        if (res.status !== 200) {
            throw new Error(result.errorMessage);
        }
        if (result.status === Api06Result.KiykshaNotFoundError) {
            setIsKiykshaNotFoundError(true);
            setContractInquiryBtnLabel("再入力");
            return false;
        }
        if (result.status === Api06Result.KiykshaJotiError) {
            history.push(`./authenticationerror`);
        }

        return true;
    }

    async function MakeHashAsync(data: ContractInfomationProps) {
        const method = "POST";
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        };
        const kana = ConvertKana(data.ContractorName);
        const request: HashRequest = {
            CustomerNumber: data.CustomerNumber,
            ContractorKana: kana,
            Address: props.address as string,
            Key: props.id as string,
        };
        const res = await fetch(`api/MakeNttData`, {
            method: method,
            headers: headers,
            credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
            body: JSON.stringify(request)//json
        });
        const result: HashResponse = await res.json();
        //異常(status200が正常)
        if (res.status !== 200) {
            //トースターの表示内容
            throw new Error(result.errorMessage);
        }

        // 契約者情報にハッシュ値とリダイレクトURLを載せて再構築
        return { hash: result.hash, url: result.redirectUri };
    }



    return (<>
        <Hidden smDown implementation="css">
            <PCindex
                Next={props.Next}
                id={props.id}
                address={props.address}
                InquiryAsync={InquiryAsync}
                radioValue={radioValue}
                clickAgreementRadio={(value) => clickAgreementRadio(value)}
                oksmLabel={oksmLabel}
                href={href}
                isKiykshaNotFoundError={isKiykshaNotFoundError}
                contractInquiryBtnLabel={contractInquiryBtnLabel}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                Next={props.Next}
                id={props.id}
                address={props.address}
                InquiryAsync={(data) => InquiryAsync(data)}
                radioValue={radioValue}
                clickAgreementRadio={(value) => clickAgreementRadio(value)}
                oksmLabel={oksmLabel}
                href={href}
                isKiykshaNotFoundError={isKiykshaNotFoundError}
                contractInquiryBtnLabel={contractInquiryBtnLabel}
            />
        </Hidden>
    </>)
};

export default ContractorInquiry;