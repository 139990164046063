import React, { Dispatch } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { MailAddressProps } from "./../../MailAddressProps";
import StyledButton from "../../../../components/Button/StyledButton";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./../../style";
import localStyle from "./MailVerificationStyle";
const cssInCode = makeStyles(style);
const localCssInCode = makeStyles(localStyle);

type Props = {
  MailState: MailAddressProps;
  Back: Function;
  AuthorizeAsync: () => Promise<void>;
};
const MailVerificationView: React.FCX<Props> = (props) => {
  const classes = cssInCode();
  const localClasses = localCssInCode();

  return (
    <>
      <Grid container>
        {/* 説明 */}
        <Grid container className={classes.information}>
          <Typography variant="body2">
            以下のメールアドレスに「口座振替払いのお申し込みメール」をお送りします。
          </Typography>
        </Grid>
        {/* アドレス表示欄 */}
        <Grid container className={localClasses.mailverificationsFrame}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              className={localClasses.mailverifications}
              variant="body2"
            >
              {props?.MailState?.address ?? "xxxxxx@saibugas-group.com"}
            </Typography>
          </Grid>
        </Grid>
        {/* 注意事項 */}
        <Grid container className={clsx(localClasses.notes)}>
          <Typography variant="body2">
            「口座振替払いのお申し込みメール」は、「@saibugas-group.com」ドメインよりお送りいたします。
          </Typography>
          <Typography variant="body2">
            迷惑メール対策等の設定を行っている場合は、ご確認をお願いいたします。
          </Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} className={classes.btnPosition}>
            <StyledButton
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={async () => await props.AuthorizeAsync()}
            >
              送信
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.btnPosition}>
            <StyledButton
              variant="contained"
              color="primary"
              className={localClasses.backBtn}
              onClick={() => props.Back()}
            >
              入力画面へ戻る
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MailVerificationView;
