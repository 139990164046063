import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

const style: Styles<Theme, {}> = (theme) => ({
    root: {
        textAlign: "center",
        marginTop: "60px",
    },
    titleGrid: {
        marginTop: "40px",
        paddingTop: "3vh",
    },
    title: {
        display: "inline-block",
        fontWeight: "bold",
        color: "dimgray",
    },
    sentenseGrid: {
        margin: "20px 0 5px 0",
    },
    sentense: {
        color: "gray",
        display: "inline-block",
    },
    whiteFrame: {
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px 35px",
        color: "gray",
        margin: "15px auto",
        width: "450px"
    },
    recommendedEnvironmentDiv: {

    },
    recommendedEnvironmentMT: {
        margin: "10px 0 0 0",
    },
    mZero: {
        margin: "0",
    },
    recommendedEnvironmentMB: {
        margin: "0 0 8px 0",
    },
    recommendedEnvironmentCenter: {
        textAlign: "center",
    },
    w100: {
        width: "100%",
    },
    bg: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.2,
        width: "100%",
        height: "100%",
    },
});

export default style;