import React, { Dispatch, useEffect, useState } from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import clsx from "clsx";

const cssInCode = makeStyles(theme => ({
    root: {

    },
    form: {
        opacity: 0,
    },
    w100: {
        width: "100%",
    },
    marginL: {
        marginLeft: "10px",
    },
    nowrap: {
        display: "inline-block",
    },
}));

const NttSiteViewMobile: React.FCX = () => {
    const classes = cssInCode();
    return (<>
        <Typography variant="body1" component="p" className={clsx(classes.w100, classes.marginL)}>
            外部サイトへ<div className={classes.nowrap}>遷移します</div>
        </Typography>
        <Typography variant="body1" component="p" className={classes.marginL}>
            そのままお待ちください
        </Typography>
    </>)
}

export default NttSiteViewMobile;