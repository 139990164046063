import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    pcMailAuthenticationTitleContainer: {
        marginTop: "50px",
        textAlign: "center",
    },
    pcMailAuthenticationTitle: {
        fontWeight: "bolder",
        color: "#74929b",
    },
    pcMailAuthenticationMessageContainer: {
        margin: "15px 0 25px",
        width: "100%",
        textAlign: "center",
    },
    remainingTime: {
        color: "#1cacf4",
        margin: "8px 1px",
    },
    remainingTimeColorRed: {
        color: "red",
    },
    remainingTimeFrame: {
        backgroundColor: "whitesmoke",
        borderRadius: "4px",
        padding: "20px 0",
        textAlign: "center",
        margin: "0 auto",
        width: "370px",
    },
    rimainingTimeSentense: {
        fontSize: "10px",
    },
    remainingTimeBolder: {
        fontWeight: "bolder",
    },
    displayNone: {
        display: "none",
    },

});


export default style;