import React, { useState, Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    makeStyles,
    styled,
    StepLabel,
    Hidden,
} from '@material-ui/core';
import { useAsyncEffect } from "use-async-effect";
/** メールアドレス 情報 */
import { MailAddressProps } from "./MailAddressProps";
/** 契約者照会 情報 */
import { ContractInfomationProps, NttProps } from "./components/ContractorInquiry/ContractInfomationProps";
/** 完了に関する情報 */
import { Response as NttResponse } from "../../api/NttDataResult/Response";
import { WaitSite } from "../../store/Overlay/action";
import { useToast } from "../../core/extensions/SnackbarExtension";

import style from "./style";
import { Delay, GetResultAsync, RequestTokenLink, UseScrollToTop } from "../../global";
import { useHistory } from "react-router";

import UserStepPC from "./PC/index";
import UserStepMobile from "./indexMobile";

export enum StepStatus {
    None = -1,
    Mail = 0,
    ContractorInquiry = 1,
    NTT = 2,
    RESULT = 3
}


const UserStep: React.FCX = () => {

    //トースト
    const toast = useToast();
    /** メールに送信されたリンクをタップした際の認証キー */
    //const [authKey, setAuthKey] = useState<{ key: string, address: string }>({ key:"", address: ""})
    const [key, setKey] = useState("");
    const [address, setAddress] = useState("");

    /** NTT DATA のサイトがCORSで飛んでこれないので、CORSを許容するFunctions経由でリダイレクトする */
    const [enc, setEnc] = useState("");
    /** マイページからシングルサインオンしてきた場合 */
    const [token, setToken] = useState("");
    /** マイページから遷移してきて、同意したかどうか */
    const [agree, setAgree] = useState("");
    /** NTT DATAのテストサイトからのクエリパラメーター */
    const [testEnc, setTestEnc] = useState("");


    //step1 メールアドレスの入力ページ数
    const [mailStep, setMailStep] = useState(0);
    //step4 申し込み結果の入力ページ数
    const [resultStep, setResultStep] = useState(0);

    // 取得するメールアドレス
    const [mailState, setMailState] = useState<MailAddressProps>();
    // 契約者照会で入力した情報
    const [contractState, setContractState] = useState<ContractInfomationProps>();
    // NTT DATA とのForm連携する際の情報
    const [nttState, setNttState] = useState<NttProps>();
    // 完了結果の情報
    const [com, setComp] = useState<NttResponse>();

    const history = useHistory();

    //くるくる
    const dispatch: Dispatch<any> = useDispatch();
    //表示されているstem
    const [currentStep, setCurrentStep] = useState(StepStatus.Mail);

    const scrollToTop = UseScrollToTop(useEffect);
    scrollToTop();

    function MoveNttSite(contact: ContractInfomationProps, nttProps: NttProps) {
        setContractState(contact);
        setNttState(nttProps);
        setCurrentStep(StepStatus.NTT);
    }

    /** 30分のタイムアウトエラー */
    function AuthenticationTimeOut() {
        setCurrentStep(StepStatus.RESULT);
        setResultStep(1);
    }

    // クエリ文字列を取得する
    useEffect(() => {
        const query = new URLSearchParams(history.location?.search);//自分のクエリ 

        const _id = query.get('id');
        if (_id) {
            setKey(_id);
            // setAuthKey({ ...authKey, key: _id });
        }
        const _address = query.get('address');
        if (_address) {
            setAddress(_address);
            // setAuthKey({ ...authKey, address:_address });
        }
        const _token = query.get('token');
        if (_token) {
            setToken(_token);
        }
        const _agree = query.get('agree');
        if (_agree) {
            setAgree(_agree);
        }
        const _testEnc = query.get('testEnc');
        if (_testEnc) {
            setTestEnc(_testEnc);
        }
        const _enc = query.get('enc');
        if (_enc) {
            setEnc(_enc);
        }

    });//[]なしはレンダリング時

    useAsyncEffect(async () => {

        const e = enc as string ?? testEnc;
        if (e) {
            ParseEnc(e);
        }

    }, [enc, testEnc])

    async function ParseEnc(enc: string) {
        // くるくる出す
        dispatch(WaitSite(true));
        try {
            const result = await GetResultAsync(enc as string);
            setComp(result);

            // 成功時
            if (result.result === "le") {
                setCurrentStep(StepStatus.RESULT);
                setResultStep(0);
            }
            // 中断時
            if (result.result === "ga") {
                setCurrentStep(StepStatus.RESULT);
                setResultStep(2);
            }
            // エラー時
            if (result.result === "cy") {
                setCurrentStep(StepStatus.RESULT);
                setResultStep(1);
            }
        } catch (error) {
            console.error(error);
            toast.Error(error.toString());
            // エラー時
            setCurrentStep(StepStatus.RESULT);
            setResultStep(1);
        } finally {
            // くるくる消す
            dispatch(WaitSite(false));
        }
    }

    // 副作用関数 マイページから支払契約番号とメアドを引き継いで呼び出された場合
    useAsyncEffect(async () => {

        // トークン無しは無視
        if (!token) {
            return;
        }

        // くるくる出す
        dispatch(WaitSite(true));
        try {
            if (!agree) {
                history.push(`/?token=${token}&location=4`);
                return;
            }
            await ParseToken();
        } catch (error) {
            console.error(error);
            toast.Error(decodeURIComponent(error.toString()));

            setCurrentStep(StepStatus.RESULT);
            setResultStep(3);

        } finally {
            // くるくる消す
            dispatch(WaitSite(false));
        }
    }, [token, agree]);


    /**
     * トークン文字列から復号化、ストレージにトークン登録、ハッシュとURL取得してNTT起動
     */
    async function ParseToken() {

        const result = await RequestTokenLink(token as string);

        // 取得した値を保持
        setNttState({ hash: result.hash, url: result.url });
        // NTT DATAの画面へ遷移
        setCurrentStep(StepStatus.NTT);
    }

    return (<>
        <Hidden smDown implementation="css">
            <UserStepPC
                mailStep={mailStep}
                setMailStep={x => setMailStep(x as number)}
                resultStep={resultStep}
                mailState={mailState as MailAddressProps}
                setMailState={x => setMailState(x as MailAddressProps)}
                currentStep={currentStep}
                setCurrentStep={x => setCurrentStep(x)}
                address={address}
                id={key}
                AuthenticationTimeOut={() => AuthenticationTimeOut()}
                MoveNttSite={(contact, nttProps) => MoveNttSite(contact, nttProps)}
                nttState={nttState as NttProps}
                com={com as NttResponse}
                scrollToStop={() => scrollToTop(true)}
            />
        </Hidden>
        <Hidden mdUp implementation="css">
            <UserStepMobile
                mailStep={mailStep}
                setMailStep={x => setMailStep(x as number)}
                resultStep={resultStep}
                mailState={mailState as MailAddressProps}
                setMailState={x => setMailState(x as MailAddressProps)}
                currentStep={currentStep}
                setCurrentStep={x => setCurrentStep(x)}
                address={address}
                id={key}
                AuthenticationTimeOut={() => AuthenticationTimeOut()}
                MoveNttSite={(contact, nttProps) => MoveNttSite(contact, nttProps)}
                nttState={nttState as NttProps}
                com={com as NttResponse}
                scrollToStop={() => scrollToTop(true)}
            />

        </Hidden>
    </>)
};

export default UserStep;