import React, { useState } from "react";
import { useFormContext } from "react-hook-form"; //useFormを使用するためインポート
import {
  Grid,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { MailAddressProps } from "../../MailAddressProps";
import { mailValidation } from "../../../../global";
import "../../../../core/extensions/StringExttension";

/* export const mailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 */

import { makeStyles } from "@material-ui/core/styles";
//スタイル
const cssInCode = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  label: {
    color: "red",
  },
  mailAddress: {
    width: "100%",
    //marginTop: "10px",
  },
  errorMessage: {
    height: "15px",
    textAlign: "right",
    marginBottom: "7px",
  },
  textalignLeft: {
    textAlign: "left",
  },
  clearStyle: {
    marginRight: -10,
    padding: 0,
  },
  hidden: {
    //テーブル（表）の行や列にこの値を指定すると、その部分を詰めて表示します。
    visibility: "collapse",
  },
}));
export type MailAddressComponentProps = {
  label?: string;
  path: string;
  required: string;
  alignment: "left" | "right";
};
function MailAddressPC(props: MailAddressComponentProps) {
  const classes = cssInCode();
  const { register, errors, setValue, getValues } =
    useFormContext<MailAddressProps>(); //親のmethods
  const id = `tel-${uuidv4()}`;

  //エラー
  function error(mailAddress?: string, mailAddressVerification?: string) {
    if (!String.IsNullOrWhiteSpace(mailAddress)) {
      return mailAddress;
    }
    return "";
  }

  //エラーメッセージ
  function hasError() {
    const hasError = !String.IsNullOrWhiteSpace(errors.address?.message);
    if (hasError) return true;

    if (props?.path === "address2") {
      return getValues("address") !== getValues("address2");
    }
    return false;
  }
  function CorrelationCheck(value) {
    if (props?.path === "address") return true;
    const address = getValues("address");
    if (address !== value) {
      return "メールアドレス（確認） が 一致しません。";
    }
    return true;
  }

  return (
    <FormControl className={classes.root}>
      {/* エラーメッセージ */}
      <FormHelperText
        error={hasError()}
        className={clsx(
          classes.errorMessage,
          props.alignment === "left" && classes.textalignLeft
        )}
      >
        {error(errors[props.path]?.message)}
      </FormHelperText>

      <Grid container>
        <Grid item className={classes.mailAddress}>
          <TextField
            className={classes.mailAddress}
            id={id}
            variant="outlined"
            placeholder="例）xxx@saibugas-group.com"
            size="small"
            type="email"
            autoComplete="email"
            name={props.path}
            label={props.label}
            error={Boolean(errors[props.path])}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              required: props.required,
              pattern: {
                value: mailValidation,
                message: "正しい形式のメールアドレスを入力してください",
              },
              validate: (x) => CorrelationCheck(x),
            })}
          ></TextField>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default MailAddressPC;
