import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import history from './history';
import store from "./AppStore";
import registerServiceWorker, { unregister } from './registerServiceWorker';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <QueryParamProvider ReactRouterRoute={Route}>
                <App />
            </QueryParamProvider>
        </Provider>
    </Router>,
    document.getElementById('root')
);

unregister()
//registerServiceWorker();
//quickHideAddressBar();

// Quick address bar hide on devices like the iPhone
//---------------------------------------------------
