import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import TitleImg from "../../../img/SuspendTitle.png";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../../PC/style";
import localStyle from "./SuspendStyle";
const cssInCode = makeStyles(style);
const localCssIncode = makeStyles(localStyle);

type Props = {
};

const SuspendView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssIncode();

    return (<>
        {/* タイトル */}
        <Grid container className={classes.pcApplicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                {/* <Typography variant="h5" className={classes.pcApplicationResultTitle}>
                    <ErrorIcon className={classes.applicationResultTitleIcon}></ErrorIcon>
                    お申し込み中止
                </Typography> */}
                <img src={TitleImg} className={classes.titleImgHeight}></img>
            </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container className={classes.pcApplicationResultMessageContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1">
                    口座振替払いのお申し込みを中止いたしました。
                </Typography>
                <Typography variant="body1">
                    ありがとうございました。
                </Typography>
            </Grid>
        </Grid>


    </>)
};

export default SuspendView;