import React, { useState, Dispatch } from "react";

import {
    Grid,
    Typography,
} from '@material-ui/core';


type Props = {
    errorCD: string | undefined;
}


const ErrorCD: React.FC<Props> = (props) => {
    switch (props.errorCD) {

        //1
        case "45":
        case "12":
        case "14":
        case "20":
        case "21":
        case "42":
            return (<>
                <Typography variant="body2">
                    エラーが発生しました。
                </Typography>
                <Typography variant="body2">
                    お手数ですが、取扱金融機関まで連絡をお願いします。
                </Typography>
            </>)
            break;

        //2
        case "40":
        case "41":
        case "43":
        case "11":
        case "19":
            return (<>
                <Typography variant="body2">
                    エラーが発生しました。
                </Typography>
                <Typography variant="body2">
                    お手数ですが、最初からやりなおしていただくか、時間をおいてからアクセスしてください。
                </Typography>
            </>)

            break;

        //4
        case "15":
            return (<>
                <Typography variant="body2">
                    ただ今の時間は、取扱金融機関のサービス時間外となっております。
                </Typography>
            </>)

            break;
        default:
            return (<>
                <Typography variant="caption">{props.errorCD}</Typography>
            </>)
    }
}

export default ErrorCD;