import React from "react";
import { useFormContext } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Hidden,
} from "@material-ui/core";
import FullNamePC from "./FullNamePC";

export type FullNameProps = {
    requiredValidation: string;
    disabled: boolean;
}

function FullName(props: FullNameProps) {
    return (<>
        <FullNamePC {...props} disabled={props.disabled} />
    </>);
}

export default FullName;