import React, { useState, useEffect } from "react";
import { useFormContext } from 'react-hook-form' //useFormを使用するためインポート
import {
    Grid,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { v4 as uuidv4 } from 'uuid';

import { ContractInfomationProps } from "../ContractorInquiry/ContractInfomationProps";
import "../../../../core/extensions/StringExttension";

import { makeStyles } from '@material-ui/core/styles';
import { CustomerNumberProps } from ".";
const cssInCode = makeStyles(theme => ({
    root: {

    },
    w100: {
        width: "100%",
        //height: "50px",
        //maxWidth: "530px",
    },
    errorMessage: {
        height: "15px",
        marginBottom: "5px"
    },
    clearStyle: {
        marginRight: -10,
        padding: 0
    },
    hidden: {
        //テーブル（表）の行や列にこの値を指定すると、その部分を詰めて表示します。
        visibility: "collapse",
    },
    disabledColor: {
        backgroundColor: "lightgray",
    },
}));

function CustomerNumberPC(props: CustomerNumberProps) {
    const classes = cssInCode();
    const { register, errors, setValue, getValues } = useFormContext<ContractInfomationProps>();
    const id = `tel-${uuidv4()}`;

    function error(customerName?: string) {
        if (!String.IsNullOrWhiteSpace(customerName)) {
            return customerName;
        }
        return "";
    }
    function hasError() {
        return !String.IsNullOrWhiteSpace(errors.CustomerNumber?.message);
    }

    function onValidValue(value) {
        const s = (value as string)?.trim();
        if (String.IsNullOrWhiteSpace(s)) {
            return true;
        }

        const contract = props.contract;
        const startChar = s[0];
        // ガスの場合
        if (contract === "gas") {
            if (startChar !== "9") {
                return props.validation;
            }
        }
        // 電気の場合
        if (contract === "electricity") {
            if (startChar !== "0") {
                return props.validation;
            }
        }

        return true;
    }

    function onChange(s: string) {
        if (!s) {
            return;
        }
        if (s.length <= 10) {
            return;
        }
        setValue("CustomerNumber", s.substring(0, 10));
    }

    return (<>

        {/* エラーメッセージ */}
        <FormHelperText error={hasError()} className={classes.errorMessage} >
            {error(errors.CustomerNumber?.message)}
        </FormHelperText>

        {/* 入力欄 */}
        <Grid container>
            <Grid item className={classes.w100}>
                <TextField
                    className={clsx(classes.w100, props.disabled ? classes.disabledColor : false)}
                    variant="outlined"
                    placeholder={props.placeHolder}
                    size="small"
                    type="number"
                    name="CustomerNumber"
                    autoComplete="number"
                    onChange={x => onChange(x.target.value)}
                    error={Boolean(errors.CustomerNumber)}
                    disabled={props.disabled}
                    inputRef={register({
                        maxLength: {
                            value: 10,
                            message: props.validation,
                        },
                        minLength: {
                            value: 10,
                            message: props.validation,
                        },
                        required: props.requiredValidation,
                        validate: x => onValidValue(x)
                    })}
                >
                </TextField>
            </Grid>
        </Grid>

    </>);
}
export default CustomerNumberPC;