import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import SubTitleImg from "../../../img/ApplicationReceptionTitle.png";
import { Response as NttResponse } from "../../../../../api/NttDataResult/Response";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../../PC/style";
import localStyle from "./ApplicationCompleteStyle";
const cssInCode = makeStyles(style);
const localCssIncode = makeStyles(localStyle);


type Props = {
    Response: NttResponse | undefined;
};
const ApplicationCompleteView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssIncode();
    return (<>

        <Grid container className={classes.pcApplicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5" className={localClasses.pcApplicationCompleteTitle}>
                    ご利用ありがとうございました
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={localClasses.pcApplicationCompleteSubTitleGrid}>
                <img src={SubTitleImg} className={localClasses.pcSubTitleImgHeight}></img>
            </Grid>
        </Grid>

        <Grid container className={localClasses.pcApplicationCompletePadding}>
            <Grid container>
                <Grid item xs={4} sm={4} md={4} className={localClasses.pcApplicationCompleteLabelFrame}>
                    <Typography className={localClasses.pcApplicationCompleteLabel}>お申し込み内容</Typography>
                </Grid>
                <Grid xs={8} sm={8} md={8} className={localClasses.pcApplicationCompleteContentFrame}>
                    <Typography className={localClasses.pcApplicationCompleteContent}>口座振替払い</Typography>
                </Grid>
            </Grid>

            <Grid container className={localClasses.pcApplicationCompleteFrameSpace}>
                <Grid item xs={4} sm={4} md={4} className={localClasses.pcApplicationCompleteLabelFrame}>
                    <Typography className={localClasses.pcApplicationCompleteLabel}>お客さま番号</Typography>
                </Grid>
                <Grid xs={8} sm={8} md={8} className={localClasses.pcApplicationCompleteContentFrame}>
                    <Typography className={localClasses.pcApplicationCompleteContent}>{props?.Response?.oksmNo}</Typography>
                </Grid>
            </Grid>

            <Grid container className={localClasses.pcApplicationCompleteFrameSpace}>
                <Grid item xs={4} sm={4} md={4} className={localClasses.pcApplicationCompleteLabelFrame}>
                    <Typography className={localClasses.pcApplicationCompleteLabel}>受付番号（Web）</Typography>
                </Grid>
                <Grid xs={8} sm={8} md={8} className={localClasses.pcApplicationCompleteContentFrame}>
                    <Typography className={localClasses.pcApplicationCompleteContent}>{props?.Response?.uktkNo}</Typography>
                </Grid>
            </Grid>

            <Grid container className={localClasses.pcApplicationCompleteFrameSpace}>
                <Grid item xs={4} sm={4} md={4} className={localClasses.pcApplicationCompleteLabelFrame}>
                    <Typography className={localClasses.pcApplicationCompleteLabel}>受付日時（Web）</Typography>
                </Grid>
                <Grid xs={8} sm={8} md={8} className={localClasses.pcApplicationCompleteContentFrame}>
                    <Typography className={localClasses.pcApplicationCompleteContent}>{props?.Response?.UktkTrkNcj}</Typography>
                </Grid>
            </Grid>

        </Grid>

        <Grid container className={clsx(localClasses.pcApplicationCompletePadding, localClasses.pcApplicationCompleteSentenseGrid)}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" className={clsx(localClasses.pcApplicationCompleteSentenseSpase, localClasses.leftAlign)}>※お客さま番号・受付番号（Web）はお問い合わせの際に必要となります。お手元にお控えください。</Typography>
                <div className={localClasses.pcApplicationCompleteSentenseSpase}>
                    <Typography variant="body2" className={localClasses.leftAlign}>※口座振替払い登録のお手続きは２～３日ほどで完了いたします。（土日祝除く）</Typography>
                    <div className={localClasses.messagePL}>
                        <Typography variant="body2" className={clsx(localClasses.leftAlign)}>また、口座振替払いの開始は、登録完了後の次回または次々回のご請求からとなります。</Typography>
                    </div>
                </div>
                <Typography variant="body2" className={clsx(localClasses.pcApplicationCompleteSentenseSpase, localClasses.leftAlign)}>※お手続き完了までのお支払いは、従来の支払い方法でのお支払いとなります。</Typography>
            </Grid>
        </Grid>
    </>);
};

export default ApplicationCompleteView;