import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    applicationCompleteTitle: {
        fontWeight: "bolder",
        color: "#74929b",

    },
    applicationCompleteSubTitleGrid: {
        margin: "10px 0 30px 0",
    },
    applicationCompleteIcon: {
        color: "#74929b",
        marginRight: "5px",
    },
    applicationCompleteSentenseContainer: {
        width: "100%",
        marginTop: "20px",
    },
    applicationCompleteSentenseSpase: {
        marginBottom: "10px",
    },
    applicationCompleteFrame: {
        width: "100%",
        marginBottom: "10px",
        backgroundColor: "whitesmoke",
    },
    applicationCompleteLabel: {
        margin: "2px 5px",
        color: "gray",

    },
    applicationCompleteContentFrame: {
        backgroundColor: "whitesmoke",
        width: "90%",
        padding: "5px 0 10px 25px",
        color: "dimgray",
    },
    applicationCompleteContent: {
        fontWeight: "bolder",

    },
    authenticationErrorViewPhoneFrame: {
        backgroundColor: "whitesmoke",
        padding: "10px 25px",
        margin: "10px auto",
        width: "420px",
    },
    leftAlign: { //左にスペース開けられる
        paddingLeft: "1em",
        textIndent: "-1.0em",
    },
    subTitleImgHeight: {
        height: "20px",
    },

});


export default style;