import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    mailAddressMB: {
        marginBottom: "15px",
    },
    border: {
        border: "1px dashed #DDDDDD",
        opacity: 1,
        margin: "15px auto 5px auto",
    },
});


export default style;