import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    pcApplicationCompleteTitle: {
        fontWeight: "bolder",
        color: "#74929b",

    },
    pcApplicationCompleteSubTitleGrid: {
        margin: "10px 0 30px 0",
    },
    pcApplicationCompleteIcon: {
        color: "#74929b",
        marginRight: "5px",
    },
    pcApplicationCompleteSentenseGrid: {
        marginTop: "35px",
    },
    pcApplicationCompleteSentenseSpase: {
        marginBottom: "15px",
    },
    pcApplicationCompletePadding: {
        padding: "0 30px"
    },
    pcApplicationCompleteFrameSpace: {
        marginTop: "6px",
    },
    pcApplicationCompleteLabelFrame: {
        backgroundColor: "whitesmoke",
        color: "gray",
    },
    pcApplicationCompleteLabel: {
        margin: "17px 20px",
    },
    pcApplicationCompleteContentFrame: {
        backgroundColor: "whitesmoke",
        color: "dimgray",
    },
    pcApplicationCompleteContent: {
        margin: "17px 20px",
        fontWeight: "bolder",
    },
    leftAlign: { //左にスペース開けられる
        paddingLeft: "1em",
        textIndent: "-1.0em",
    },
    messagePL: {
        paddingLeft: "1em"
    },
    pcSubTitleImgHeight: {
        height: "20px",
    },
    pcAuthenticationErrorViewMessageSpace: {
        marginBottom: "5px",
    }

});


export default style;