import React from "react";
import {
    makeStyles,
    styled,
    Grid,
    Stepper,
    Step,
    StepLabel,
    Typography,
    StepIcon,
    SvgIcon,
} from '@material-ui/core';
import { Collapse } from 'react-collapse';
import { mdiAccountEdit } from '@mdi/js';

/** メール入力画面 */
import MailInputView from "../components/MailInput";
/** メール確認・送信画面 */
import MailVerificationView from "../components/MailVerification";
/** メール認証待ちのタイマーカウント画面 */
import MailAuthenticationView from "../components/MailAuthentication";
/** 契約者情報照会画面 */
import ContractorInquiryView from "../components/ContractorInquiry";
/** 契約者情報照会確認画面 */
import ApplicationCompleteView from "../components/ApplicationComplete/index";
/** エラー画面 */
import ErrorView from "../components/Error";
/** 中断画面 */
import SuspendView from "../components/Suspend";
/** 認証エラー画面 */
import AuthenticationError from "../components/AuthenticationError";

/** メールアドレス 情報 */
import { MailAddressProps } from "../MailAddressProps";
/** NTTサイト転送 情報 */
import NttSiteView from "../components/NttSiteView";

import { UserStepProps } from "../UserStepProps";


/** css in js(ts)  */
import clsx from "clsx";
import style from "../style";

const cssInCode = makeStyles(style);

//stepアイコンのスタイル
const StyledStepLabel = styled(StepLabel)({
    /** アクティブなタイトルのスタイル */
    "& .MuiStepLabel-active": {
        color: "#1cacf4"
    },
    /** 完了したタイトルのスタイル */
    "& .MuiStepLabel-completed": {
        color: "grey"
    },
    /** アクティブなアイコンのスタイル */
    "& .MuiSvgIcon-root": {
        color: "#1cacf4"
    },
    /** 完了したアイコンのスタイル */
    "& .MuiStepIcon-completed": {
        color: "#1cacf4 !important"
    },
    /** 未完了のアイコンのスタイル */
    "& .MuiStepIcon-root": {
        color: "grey"
    }
});

//stepContent
interface StepContentPageProps {
    children?: React.ReactNode;
    index?: any;
    value?: any;
    className?: string;
}

//step
enum StepStatus {
    Mail = 0,
    ContractorInquiry = 1,
    NTT = 2,
    RESULT = 3
}

/* 外に書くとどこにも依存していないから中に書くより安全、、？ */
/* ページ遷移 */
function StepContentPage(props: StepContentPageProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="stepcontentpage"
            hidden={value !== index}
            id={`scrollable-force-stepcpntentpage-${index}`}
            aria-labelledby={`scrollable-force-step-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const UserStepPC: React.FCX<UserStepProps> = (props) => {
    const classes = cssInCode();

    function EditIcon() {
        return (<SvgIcon className={clsx(classes.editIconStyle, classes.editIconPathStyle)}>
            <path className={classes.editIconPathStyle} d={mdiAccountEdit}></path>
        </SvgIcon>)
    }

    return (
        <div className={classes.pcRoot}>
            {/*白フレーム*/}
            <Grid container className={classes.pcGridWhite}>
                <Grid container>
                    {/*タイトル*/}
                    <Grid container className={clsx(classes.titleBar, classes.stepTitle)}>
                        <Typography variant="h5" component="span"
                            className={classes.bolder}
                        >
                            口座振替払いのお申し込み
                        </Typography>
                        <Typography variant="subtitle1" component="span"
                            className={clsx(classes.bolder, classes.subTitlePosition)}
                        >
                            （ガス料金等・電気料金等）
                        </Typography>
                    </Grid>

                    {/*ステッパー*/}
                    <Grid container>
                        <Stepper
                            activeStep={props.currentStep}
                            orientation="horizontal"//横
                            style={{ backgroundColor: "transparent", textAnchor: "inherit" }}
                            alternativeLabel
                            className={clsx(classes.center, classes.stepContainer)}
                        >
                            {/*ステップ1*/}
                            <Step >
                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.Mail ? EditIcon : StepIcon}
                                >
                                    メールアドレスの入力
                                </StyledStepLabel>
                            </Step>

                            {/*ステップ2*/}
                            <Step>
                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.ContractorInquiry ? EditIcon : StepIcon}
                                >
                                    ご契約情報の入力
                                </StyledStepLabel>
                            </Step>

                            {/*ステップ3*/}
                            <Step>
                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.NTT ? EditIcon : StepIcon}                                >
                                    口座振替情報の入力
                                      <Typography variant="caption" component="p">（外部サイト）</Typography>
                                </StyledStepLabel>
                            </Step>

                            {/*ステップ4*/}
                            <Step>
                                <StyledStepLabel
                                    StepIconComponent={props.currentStep == StepStatus.RESULT ? EditIcon : StepIcon}                                >
                                    お申し込み完了
                                </StyledStepLabel>
                            </Step>
                        </Stepper>
                    </Grid>

                    {/*ステップ1　メールアドレス入力*/}
                    <StepContentPage
                        className={classes.w100}
                        index={StepStatus.Mail}
                        value={props.currentStep}
                    >
                        {/* メールアドレス入力　MailInputView */}
                        <Collapse isOpened={props.mailStep === 0}>
                            <MailInputView
                                Next={() => props.setMailStep(1)}
                                State={props.mailState}
                                SetState={x => props.setMailState(x as MailAddressProps)}
                            />
                        </Collapse>
                        {/* メールアドレス確認　MailVerificationView */}
                        <Collapse isOpened={props.mailStep === 1}>
                            <MailVerificationView
                                MailState={props.mailState as MailAddressProps}
                                SetState={x => props.setMailState(x as MailAddressProps)}
                                Next={() => props.setMailStep(2)}
                                Back={() => props.setMailStep(0)}
                                scrollToTop={props.scrollToStop}
                            />
                        </Collapse>
                        {/* メールアドレス認証　MailAuthenticationView */}
                        <Collapse isOpened={props.mailStep === 2}>
                            <MailAuthenticationView
                                TimeoutSec={props.mailState?.timeoutSec ?? -1}
                                Next={() => props.setCurrentStep(StepStatus.ContractorInquiry)}
                                Timeout={() => props.AuthenticationTimeOut()}
                                MailState={props.mailState as MailAddressProps}
                                id={props.id}
                                address={props.address}
                            />
                        </Collapse>

                    </StepContentPage>

                    {/*ステップ2　契約情報照会 ContractorInquiryView */}
                    <StepContentPage
                        className={classes.w100}
                        index={StepStatus.ContractorInquiry}
                        value={props.currentStep}
                    >
                        <ContractorInquiryView
                            Next={(contract, ntt) => props.MoveNttSite(contract, ntt)}
                            id={props.id}
                            address={props.address}
                            scrollToTop={props.scrollToStop}
                        />
                    </StepContentPage>

                    {/*ステップ3　NTT申し込み受付 NttSiteView */}
                    <StepContentPage
                        className={classes.w100}
                        index={StepStatus.NTT}
                        value={props.currentStep}
                    >
                        <NttSiteView
                            hash={props.nttState?.hash as string}
                            url={props.nttState?.url as string}
                        />


                    </StepContentPage>

                    {/*ステップ4　WEB申し込み結果 */}
                    <StepContentPage
                        className={classes.w100}
                        index={StepStatus.RESULT}
                        value={props.currentStep}
                    >
                        <Collapse isOpened={props.resultStep === 0}>
                            <ApplicationCompleteView
                                Response={props.com}
                            />
                        </Collapse>
                        <Collapse isOpened={props.resultStep === 1}>
                            <ErrorView
                                Response={props.com}
                            />
                        </Collapse>
                        <Collapse isOpened={props.resultStep === 2}>
                            <SuspendView
                            />
                        </Collapse>
                        <Collapse isOpened={props.resultStep === 3}>
                            <AuthenticationError
                            />
                        </Collapse>
                    </StepContentPage>

                </Grid>
            </Grid>
        </div >
    );
}
export default UserStepPC;