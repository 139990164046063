import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    TextField
} from '@material-ui/core';
import { NttTestProps } from "./NttTestProps";
/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
const cssInCode = makeStyles(style);

const NttTest: React.FCX<NttTestProps> = (props) => {
    const css = cssInCode();
    return (
        <div className={css.root}>

            <Grid container className={css.titleLine}>
                NTT TEST
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        お客さま名
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <TextField value={props.oksmNmState} onChange={(e) => props.setOksmNmState(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        お客さま番号
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.oksmNo}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        会社CD
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.kaishCD}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        収納機関
                        <div className={css.noLineBreak}>取り扱いNo</div>
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.storageOrganizationNo}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        収納機関
                        <div className={css.noLineBreak}>取り扱い年月日</div>
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.storageOrganizationYMD}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        収納機関
                        <div className={css.noLineBreak}>取り扱い時間</div>
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.storageOrganizationTime}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        ハッシュ
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <Typography>
                        {props.hash}
                    </Typography>
                </Grid>
            </Grid>


            <Grid container className={clsx(css.listContainer, css.mAuto, css.colorGray)}>
                <Grid item xs={4} sm={4} md={4} className={css.pcList}>
                    <Typography>
                        エラーコード
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} className={css.pcList}>
                    :
                </Grid>
                <Grid item xs={7} sm={7} md={7} className={css.pcList}>
                    <TextField value={props.errorCD} onChange={e => props.setErrorCD(e.target.value)}>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container className={css.center}>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Success")}
                    >
                        Success
                </Button>

                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Faild")}
                    >
                        Faild
                </Button>

                    <Button
                        variant="outlined"
                        className={css.button}
                        onClick={async () => await props.TestAsync("Suspends")}
                    >
                        Suspends
                </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default NttTest;