import React, { useState, useEffect } from "react";
import { useFormContext } from 'react-hook-form' //useFormを使用するためインポート
import encoding, { toZenkakuCase, toKatakanaCase, toZenkanaCase } from 'encoding-japanese';
import {
    Grid,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { v4 as uuidv4 } from 'uuid';
import { ConvertKana, Delay, IsKanjiRegx, Lazy } from "../../../../global";
import { FullNameProps } from ".";
import { ContractInfomationProps } from "../ContractorInquiry/ContractInfomationProps";
import "../../../../core/extensions/StringExttension";

import { makeStyles } from '@material-ui/core/styles';
//スタイル
const cssInCode = makeStyles(theme => ({
    root: {

    },
    name: {
        width: "100%",
        //  height: "50px",
        // marginRight: "5px",
    },
    errorMessage: {
        height: "15px",
        marginBottom: "5px"
    },
    clearStyle: {
        marginRight: -10,
        padding: 0
    },
    hidden: {
        //テーブル（表）の行や列にこの値を指定すると、その部分を詰めて表示します。
        visibility: "collapse"
    },
    disabledColor: {
        backgroundColor: "lightgray",
    },
}));

function FullNamePC(props: FullNameProps) {
    const classes = cssInCode();
    const { register, errors, setValue, getValues } = useFormContext<ContractInfomationProps>();//親のmethods
    const id = `tel-${uuidv4()}`;

    const [composing, setComposing] = useState(false)

    //エラー
    function error(name?: string) {
        if (!String.IsNullOrWhiteSpace(name)) {
            return name;
        }
        return "";
    }

    //エラーメッセージ
    function hasError() {
        return !String.IsNullOrWhiteSpace(errors.ContractorName?.message);
    }


    function IsValidKana(s: string) {
        if (!s) return true;
        if (hasKanji(s)) return "カタカナで入力してください。";

        autoInput(s);

        return true;
    }

    function hasKanji(s: string) {
        for (let index = 0; index < s.length; index++) {
            const value = s[index];
            if (IsKanjiRegx.test(value)) {
                return true;
            }
        }
        return false;
    }

    function onChanging(e: React.CompositionEvent<HTMLDivElement>) {
        setComposing(true);
    }
    function onChanged(e: React.CompositionEvent<HTMLDivElement>) {
        setComposing(false);
    }

    function autoInput(s: string) {
        // 入力が未確定なら抜ける
        if (composing) return;

        const newValue = ConvertKana(s);

        setValue("ContractorName", newValue);
    }



    return (<>

        {/* エラーメッセージ */}
        <FormHelperText error={hasError()} className={classes.errorMessage}>
            {error(errors.ContractorName?.message)}
        </FormHelperText>


        <Grid container>
            <Grid item className={classes.name}>
                <TextField
                    className={clsx(classes.name, props.disabled ? classes.disabledColor : false)}
                    variant="outlined"
                    placeholder="サイブタロウ"
                    size="small"
                    type="text"
                    name="ContractorName"
                    error={Boolean(errors.ContractorName)}
                    disabled={props.disabled}
                    autoComplete="text"
                    inputRef={register({
                        required: props.requiredValidation,
                        /* 
                        pattern: {
                            value: fullNameValidation,
                            message: "漢字での入力はできません。",
                        },
                        */
                        validate: x => IsValidKana(x)
                    })}
                    onCompositionStart={e => onChanging(e)}
                    onCompositionEnd={e => onChanged(e)}
                ></TextField>
            </Grid>
        </Grid>

    </>);
}

export default FullNamePC;