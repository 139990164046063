import React, { useState, useEffect, Dispatch } from "react";
import { useDispatch } from "react-redux";
import {
    Hidden,
} from '@material-ui/core';
import { useAsyncEffect } from "use-async-effect";
import { MailAddressProps } from "../../MailAddressProps";
import { Request as Req } from "../../../../api/CheckAuthenticationStatus/Request";
import { Response as Res } from "../../../../api/CheckAuthenticationStatus/Response";
import { Request as AuthReq } from "../../../../api/AuthorizeAccount/Request";
import { Response as AuthRes } from "../../../../api/AuthorizeAccount/Response";
import { useToast } from "../../../../core/extensions/SnackbarExtension";

import { WaitSite } from "../../../../store/Overlay/action";
import { isMobile } from "react-device-detect";
import PCindex from "./PC/MailAuthenticationView";
import Mobileindex from "./MailAuthenticationView";


type Props = {
    TimeoutSec: number;
    Next: Function;
    Timeout: Function;
    MailState: MailAddressProps;
    id?: string | null;
    address?: string | null;
}

const MailAuthenticationView: React.FCX<Props> = (props) => {

    //くるくる
    const dispatch: Dispatch<any> = useDispatch();
    //カウントダウン
    const [counter, setCounter] = React.useState(-1);
    //認証番号入力の残り時間
    const [rimainingTime, setRimainingTime] = useState("");
    //残り時間の文字色
    const [rimainingTimeOut, setRimainingTimeOut] = useState(false);
    const toast = useToast();

    const [rimainingMessage, setRimainingMessage] = useState("");

    useAsyncEffect(async () => {
        if (!props?.id) return;

        // くるくる出す
        dispatch(WaitSite(true));
        try {
            const ret = await AuthAsync();
            if (ret) {
                props.Next();
            }
        } finally {
            // くるくる消す
            dispatch(WaitSite(false));
        }
    }, [props?.id]);//リンクがクリックされるとキーが入る

    // 副作用関数(タイムアウト値が設定されるタイミング≒初回のみ)
    useEffect(() => {
        // if (props.TimeoutSec < 0) return;
        // 制限時間セットと同時にカウントスタート
        setCounter(30 * 60);
    }, [props?.TimeoutSec]);

    // 副作用関数（counter値の変更の度にレンダリングを実行する）
    useEffect(() => {
        if (counter === -1) return;

        /* setInterval：一定時間ごとに特定の処理を繰り返す 第一引数：関数定義　第二引数：一定時間の指定（ミリ秒）*/
        const timer = setInterval(async () => {
            const newCount = counter - 1; //制限時間から1秒引く　
            //刻む
            const minutes: number = Math.floor(newCount / 60);//分数
            setRimainingTime(minutes.toString());//padStart：元の文字列を指定した長さに変更するメソッドです。長さが足りない場合は指定した文字列を先頭に繰り返しで追加

            //ゼロ
            if (newCount <= 0) {
                setRimainingTimeOut(true);
                setRimainingMessage("タイムアウトしました");
                clearInterval(timer);//clearInterval:setIntervalの処理を停止する

                // 最後に認証チェック　万が一0秒で認証していた時のため
                if (await HeatbeatAsync()) {
                    props.Next();
                    return;
                }
                // props.Timeout();
            }
            else {
                setCounter(newCount);
                // 5秒に一回、サーバーに問い合わせて認証が通っていることを確認する
                if (newCount % 5 === 0 && await HeatbeatAsync()) {
                    // 認証OK   
                    clearInterval(timer);//clearInterval:setIntervalの処理を停止する
                    props.Next();
                }
            }
        }, 1000);
        return () => clearInterval(timer);//??
    }, [counter]);

    //認証されたかチェックする
    async function HeatbeatAsync() {
        let result = false;
        if ((!props.MailState) ||//入っているか
            (!props.MailState?.address) ||
            (!props.MailState?.key) ||
            (!props?.id)) {
            return result;
        }

        try {
            const method = "POST";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            };
            const request: Req = {
                key: props.MailState.key,
                address: props.MailState.address,
            }
            const res = await fetch(`api/CheckAuthenticationStatus`, {
                method: method,
                headers: headers,
                credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
                body: JSON.stringify(request)//json
            });

            const response: Res = await res.json();
            if (res.status === 200) {
                result = response.success;
                setCounter(response.defference ?? 0);
            } else {
                console.log(`heatbeat...${response.message}`);
            }
        } catch (error) {
            console.log(error);
        }
        return result;
    }

    //クリックされた後に呼ばれて認証する人
    async function AuthAsync() {
        try {
            const method = "POST";
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            };
            const request: AuthReq = { key: props?.id as string, address: props?.address as string };
            const res = await fetch(`api/AuthorizeAccount`, {
                method: method,
                headers: headers,
                credentials: 'include',//認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
                body: JSON.stringify(request)//json
            });
            const result: AuthRes = await res.json();
            //異常(status200が正常)
            if (res.status !== 200) {
                //トースターの表示内容
                toast.Error(`${result.ErrorMessage}`);
                return false;//異常はここで終わる
            }
            return true;
        } catch (error) {
            console.error(error);
            toast.Error(error.toString());
        }
        return false;
    }



    return (<>
        <Hidden smDown implementation="css">
            <PCindex
                TimeoutSec={props.TimeoutSec}
                Next={props.Next}
                Timeout={props.Timeout}
                MailState={props.MailState}
                id={props.id}
                address={props.address}
                rimainingTimeOut={rimainingTimeOut}
                rimainingTime={rimainingTime}
                rimainingMessage={rimainingMessage}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                TimeoutSec={props.TimeoutSec}
                Next={props.Next}
                Timeout={props.Timeout}
                MailState={props.MailState}
                id={props.id}
                address={props.address}
                rimainingTimeOut={rimainingTimeOut}
                rimainingTime={rimainingTime}
                rimainingMessage={rimainingMessage}
            />
        </Hidden>
    </>)
};

export default MailAuthenticationView;