import React, { Dispatch, useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    SvgIcon,
    BottomNavigation,
    BottomNavigationAction,
    Typography,
    Grid,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';


import Backdrop from '@material-ui/core/Backdrop';
import { IRootState } from "../../store/rootModel";
import { UpdateTitle } from "../../store/Layout/action";



/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
import { resolve } from 'dns';

const cssInCode = makeStyles(style);
const BottomNav: React.FC = () => {
    const classes = cssInCode();
    const dispatch = useDispatch();
    return (
        <footer
            className={classes.bottomNav}
        >
            <Grid container>
                <Grid item xs={12} className={classes.container} justify="center" container spacing={2}> 
                    <Grid item >
                        <a href="http://www.saibugas.co.jp/policy/kojin_hogo.htm"
                            rel="noreferrer" target="_blank" 
                            className={classes.link}
                        >
                            個人情報について
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="javascript:void(0)" id="cobrowse"  
                            rel="noreferrer"
                            className={classes.link}
                        >                 
                            画面共有によるサポート
                        </a>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.container}>
                    <Typography
                        align="center"
                        variant="caption"
                        className={classes.copylight}
                    >
                        ©SAIBUGAS Co.,Ltd.
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
};

export default BottomNav;