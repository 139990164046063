import React, { Dispatch } from "react";
import { FormProvider, useForm } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import { MailAddressProps } from "../../../MailAddressProps";
import StyledButton from "../../../../../components/Button/StyledButton";


import style from "../../../PC/style";
import localStyle from "./MailInputStyle";
import MailAddress from "../../../components/MailAddress";
const cssInCode = makeStyles(style);
const localCssInCode = makeStyles(localStyle);



type Props = {
    Next: (data: MailAddressProps) => (void);
}

const MailInputView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssInCode();
    const methods = useForm<MailAddressProps>();
    const {
        register: register,//input/select の Ref とバリデーションルールを React Hook Form に登録 (register) することができます。
        handleSubmit: submit,//バリデーションに成功するとフォームデータを返します。
        errors: errors,//各inputのエラー、エラーメッセージが含まれています。
        reset: reset,//フィールド値とエラーをデフォルト値でリセットします。
        getValues: getValues,
        setValue: setValue,
    } = methods;

    return (<FormProvider {...methods}>

        {/*onSubmitはonChangeイベントリスナーをアタッチして再度バリデーションを行う
          *submitをクリックすることでformに入力された値を送ることができる*/}
        <form onSubmit={submit(data => props.Next(data))}>
            {/* 説明 */}
            <Grid container className={classes.pcInformation}>
                <Typography variant="body1">
                    メールアドレスを入力して「確認画面へ」ボタンを押してください。
                </Typography>
                <Typography variant="body1">
                    お申し込みフォームのURLが載ったメールを送信いたします。そちらからお申し込みページにお進みください。
                </Typography>
            </Grid>

            {/*入力欄*/}
            <Grid container/*  className={classes.pcInputFrame} */>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.pcInputTitle} variant="h6">メールアドレスの入力</Typography>
                    {/*メールアドレス*/}
                    <Grid container className={classes.pcInputContainer}>
                        <Grid item xs={12} sm={12} md={4} className={classes.pcInputLabelMT}>
                            <Typography component="span" variant="body2" className={classes.pcInputLabel}>メールアドレス</Typography>
                            <Typography component="span" className={classes.requiredMark}>必須</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8}>
                            <MailAddress
                                path="address"
                                required="メールアドレスを入力してください"
                                alignment="left"
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={classes.borderStyle}></Grid>

                    {/*メールアドレス（確認）*/}
                    <Grid container className={classes.pcInputContainer}>
                        <Grid item xs={12} sm={12} md={4} className={classes.pcInputLabelMT}>
                            <Typography component="span" variant="body2" className={classes.pcInputLabel}>ご確認</Typography>{/*ラベル*/}
                            <Typography component="span" className={classes.requiredMark}>必須</Typography>{/*必須*/}
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} >
                            <MailAddress
                                path="address2"
                                required="メールアドレス（ご確認）を入力してください"
                                alignment="left"
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid >


            {/*ボタン*/}
            < Grid container >
                <Grid item xs={12} sm={12} md={12} className={classes.pcBtnPosition}>
                    <StyledButton
                        variant="contained"
                        type="submit"//onSubmit
                        className={classes.pcBtn}
                        color="primary"
                    //clickはformで実装
                    >
                        確認画面へ
                    </StyledButton>
                </Grid>
            </Grid >
        </form >
    </FormProvider>);
};

export default MailInputView;