import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    backBtn: {
        padding: "10px",
        width: "95%",
        marginTop: "10px",
        minWidth: "205px",
        color: "#1cacf4",
        backgroundColor: "white",
        fontWeight: "bolder",
        border: "1px solid #1cacf4",

    },
    mailverificationsFrameTitle: {
        backgroundColor: "#74929b",
        color: "white",
        borderRadius: "4px 4px 0 0",
        padding: "8px 20px",
        fontWeight: "bold",
        boxShadow: "0px 0px 0pt 0pt",
        fontSize: "15px",
    },
    mailverifications: {
        padding: "14px 15px",
        fontWeight: "bolder",
        wordBreak: "break-all",
    },
    notes: {
        margin: "20px 0 0px 0",
        width: "100%",
    },
    mailverificationsFrame: {
        width: "92%",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "6px",
        backgroundColor: "whitesmoke",
    },
});


export default style;