import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
} from '@material-ui/core';

import TitleImg from "../../img/SuspendTitle.png";
/** css in js(ts)  */
import clsx from "clsx";
import style from "./../../style";
import localStyle from "./SuspendStyle";
const cssInCode = makeStyles(style);
const localCssIncode = makeStyles(localStyle);

type Props = {
};

const SuspendView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = localCssIncode();

    return (<>
        {/* 説明 */}
        <Grid container className={classes.applicationResultTitleContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <img src={TitleImg} className={classes.titleImgHeight}></img>
            </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container className={classes.applicationResultMessageContainer}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2">
                    口座振替払いのお申し込みを
                </Typography>
                <Typography variant="body2">
                    中止いたしました。
                </Typography>
                <Typography variant="body2">
                    ありがとうございました。
                </Typography>
            </Grid>
        </Grid>

    </>)
};

export default SuspendView;