import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    pcContractInquiryInformation: {
        minWidth: "230px",
        maxWidth: "1180px",
        color: "#74929b",
        marginLeft: "20px",
    },
    pcContractInquiryInformationSentence: {
        minWidth: "230px",
        maxWidth: "1180px",
        margin: "5px 20px 10px 20px",
    },
    pcContractInquiryInformationCheckIcon: {
        fontSize: "inherit",
        color: "#1cacf4",
        marginRight: "5px",
    },
    pcContractInquiryInformationCheckSentence: {
        marginTop: "5px",
    },
    pcContractInquiryErrorMessageCol: {
        minWidth: "230px",
        maxWidth: "1180px",
        marginLeft: "20px",
    },
    pcContractInquiryErrorMessage: {
        color: "red",
    },
    contractInquiryErrorMessageSpace: {
        marginTop: "5px",
    },
    rarioBtnColor: {
        color: "gray",
    },
    oksmNoConfirmationLinkSentence: {
        color: "gray",
        marginTop: "5px",
    },
    pcCustomerNumberLabelMT: {
        marginTop: "20px",
    },
    pcContractorInquiryLabelMT: {
        marginTop: "24px",
    },
    pcContractorInquiryInputContainer: {
        margin: "10px 35px 25px 35px",
    },
    pcContractInquiryBtn: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "230px",
        color: "white",
        backgroundColor: "#1cacf4",
    },
    radio: {
        '&$checked': {
            color: '#1cacf4'
        }
    },
    checked: {},
    colorGray: {
        color: "gray",
        fontSize: "11px",
    },
    moveNavigationGrid: {
        marginTop: "10px",
        textAlign: "center",
    },
    pcLabelDisplay: {
        display: "inline-block",//改行されないように
    },
    pcContractorRadioLabelMT: {
        marginTop: "8px",
    },
    radioSpan: {
        /* CSSのセレクターは & スペースで続けて書くと可能  */
        "& > span": {
            fontWeight: "bold"
        }
    },
    w100: {
        width: "100%",
    },
    bolder: {
        fontWeight: "bolder",
    },
    display: {
        display: "inline",
    },
    displayNone: {
        display: "none",
    },

});


export default style;