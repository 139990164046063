import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        padding: 10,
        minHeight: "100%",
        marginBottom: "5rem",
        marginTop: "1rem",
    },
    applicationCompleteViewMargin: {
        margin: "-10px",
        padding: " 0 8px",
    },

    w100: {
        width: "100%",
    },

    center: {
        textAlign: "center",
        width: "100%",
    },
    mLRAuto: {
        marginLeft: "auto",
        marginRight: "auto",
    },

    verticalAlignMiddle: {
        verticalAlign: "middle",
    },

    providerTitle: {
        height: "auto",
        fontSize: "large",
        position: "absolute",
    },
    icon: {
        height: 24,
        width: 24,
    },
    playDrawer: {
        marginLeft: drawerWidth,
    },

    containerMargin: {
        marginBottom: "20px",
    },
    stepIconColor: {
        color: "white",
        border: "1px solid #1cacf4",
        borderRadius: "50px",
        backgroundColor: "#1cacf4"
    },


    step2FrameTitle: {
        backgroundColor: "#74929b",
        color: "white",
        borderRadius: "4px 0 0 4px",
        padding: "16px 0",
        fontWeight: "bold",
        fontSize: "17px",
    },
    step2FrameAddress: {
        backgroundColor: "whitesmoke",
        borderRadius: "0px 4px 4px 0",
        padding: "16px 24px",
    },
    step1NextButton: {
        // backgroundColor: "#1cacf4",
        // color: "white",
        padding: "10px",
        width: "90%",
        marginTop: "20px",
        maxWidth: "400px",
    },
    stepNextButton: {
        // backgroundColor: "#1cacf4",
        // color: "white",
        padding: "10px",
        width: "95%",
        marginTop: "20px",
        maxWidth: "200px",
    },
    stepNextButtonCol: {
        textAlign: "left",
        paddingLeft: "5px,"
    },
    stepBackButton: {
        // backgroundColor: "white",
        // color: "#1cacf4",
        padding: "10px",
        width: "95%",
        marginTop: "20px",
        // border: "1px solid #1cacf4",
        maxWidth: "200px",
    },
    stepaBackButtonCol: {
        textAlign: "right",
        paddingRight: "5px",
    },
    stepResetButton: {
        //backgroundColor: "#ff9d64",
        // color: "white",
        padding: "10px",
        width: "90%",
        marginTop: "20px",
    },

    titleBar: {
        backgroundColor: "transparent", /*背景透明に*/
        borderLeft: "solid 6px #1cacf4",
    },
    stepTitle: {
        paddingLeft: "15px",
    },
    gridWhite: {
        backgroundColor: "white",
        padding: "26px 12px",
        borderRadius: "10px",
        //marginTop: "20px",
        maxWidth: "920px",
        //maxWidth: "1245px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    gridGray: {
        backgroundColor: "whitesmoke",
        borderRadius: "7px",
        minWidth: "230px",
        maxWidth: "1180px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    bolder: {
        fontWeight: "bolder",
    },
    stepper: {
        padding: "25px 5px",
        borderLeftColor: "red",
    },
    stepLabel: {
        color: "#1cacf4",
    },
    editIconStyle: {
        background: "#1cacf4",
        height: "35px",
        width: "35px",
        borderRadius: "50%",
        padding: "7px",
        marginLeft: "-5px"
    },
    editIconPathStyle: {
        color: "white !important"
    },
    textFieldName: {
        width: "40%",
        height: "50px",
        maxWidth: "265px",
        marginRight: "5px",
    },

    textFieldTel: {
        width: "78px",
        height: "50px",
    },
    remainingTime: {
        textAlign: "right",
        margin: "10px 60px",
        fontSize: "12px",
    },
    step2Message: {
        fontSize: "15px",
        fontWeight: "bolder",
        marginBottom: "20px",
    },
    step2precaution1: {
        marginTop: "10px",
    },
    step2precaution2: {
        fontSize: "11px",
        color: "gray",
        marginTop: "10px"
    },
    step2Typography: {
        marginTop: "20px",
    },
    step2BackButton: {
        padding: "10px",
        width: "90%",
        marginTop: "20px",
        maxWidth: "400px",
    },
    step5Message1: {
        margin: "12px 0",
    },
    step5MessageSpace: {
        margin: "5px 0",
    },
    step3Button: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "230px",
    },
    hyphen: {
        padding: "10px 5px 0 5px",
        color: "#d0d0d0",
    },
    clearStyle: {
        marginRight: -10,
        padding: 0
    },
    hidden: {
        //テーブル（表）の行や列にこの値を指定すると、その部分を詰めて表示します。
        visibility: "collapse"
    },
    colorGray: {
        color: "darkgray",
    },
    InputconfirmationContentRow: {
        padding: "30px 30px",
    },
    offShadow: {
        boxShadow: "none"
    },

    //PC用
    pcRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        minHeight: "100%",
        marginBottom: "6rem",
        marginTop: "1rem",
    },
    pcGridWhite: {
        backgroundColor: "white",
        padding: "26px 12px 60px 12px",
        borderRadius: "10px",
        marginTop: "20px",
        width: "70%",
        // maxWidth: "920px",
        maxWidth: "1245px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    pcStepContent: {
        margin: "35px 50px 30px 50px",
        // maxWidth: "900px",
        width: "90%",
    },
    pcStepContentCheck: {
        minWidth: "230px",
        maxWidth: "1180px",
        margin: "5px 20px 40px 20px",
    },
    pcInquirySentence: {
        minWidth: "230px",
        maxWidth: "1180px",
        color: "#74929b",
        marginLeft: "20px",
    },
    pcInquirySentence2: {
        minWidth: "230px",
        maxWidth: "1180px",
        marginLeft: "20px",
    },
    pcStepTitleGrid: {
        marginTop: "50px",
    },
    pcStep3Content: {
        margin: "35px 0 50px",
        // maxWidth: "900px",
        width: "100%",
    },
    pcErrorMessage: {
        margin: "35px 0 30px",
        // maxWidth: "900px",
        width: "100%",
    },
    /*  pcDivContents: {
         padding: "16px 27px",
     }, */
    pcInputContainer: {
        margin: "0 20px 0 20px",
        width: "100%",
    },
    texFieldCol: {
        paddingTop: "8px",
    },
    pcStep1NextButton: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "205px",
    },
    subTitlePosition: {
        margin: "3px 0 0 6px",
    },
    stepContainer: {
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    pcStep2precaution2: {
        fontSize: "11px",
        color: "gray",
        marginTop: "35px",
        marginBottom: "30px",
    },
    pcTextFieldTel: {
        width: "22%",
        height: "50px",
    },
    pcStep2Frame: {
        width: "92%",
        marginLeft: "auto",
        marginRight: "auto",
    },


    titleColor: {
        color: "#74929b",
    },
    colorRed: {
        color: "red",
    },
    errorItemSpace: {
        marginLeft: "15px",
    },
    noLink: {
        color: "gray",
        paddingTop: "3px",
    },
    InputConfirmationTitleCol: {
        padding: "30px 0",
        textAlign: "center",
    },
    InputConfirmationTitle: {
        color: "#74929b",
        fontWeight: "bolder",
        paddingBottom: "10px",
    },




    /* スマホ */
    btnPosition: {
        textAlign: "center",
    },
    btn: {
        padding: "10px",
        width: "95%",
        marginTop: "20px",
        minWidth: "205px",
        color: "white",
        backgroundColor: "#1cacf4",
        // fontWeight: "bolder",
    },
    inputLabel: {
        fontWeight: "bolder",
    },
    requiredMark: {
        backgroundColor: "#ef4f41",
        borderRadius: "40px",
        color: "white",
        marginLeft: "5px",
        padding: "3px 10px",
        fontSize: "11px",
        display: "inline-block",
    },
    input: {
        width: "100%",
        height: "50px",
        maxWidth: "530px",
    },
    inputTitle: {
        color: "#74929b",
        fontWeight: "bold",
        marginBottom: "20px",
    },
    inputMB: {
        marginBottom: "20px",
    },
    frame: {
        border: "2px solid #74929b",
        borderRadius: "7px",
        boxShadow: "0px 0px 0pt 0pt",
        minWidth: "230px",
        //maxWidth: "735px",
        maxWidth: "1180px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    frameTitle: {
        backgroundColor: "#74929b",
        color: "white",
        borderRadius: "4px 4px 0 0",
        padding: "8px 10px",
        fontWeight: "bold",
        boxShadow: "0px 0px 0pt 0pt",
        fontSize: "15px",
    },
    borderStyle: {
        border: "1px dashed #DDDDDD",
        opacity: 1,
        margin: "0 auto 15px auto",
    },
    stepContent: {
        margin: "20px 0",
    },
    frameP: {
        padding: "15px 10px 0 15px",
    },
    /*     textFieldLabelDiv: {
            margin: "10px 0",
        }, */
    information: {
        margin: "20px 0 20px 0",
        width: "100%",
    },
    linkColor: {
        color: "#1cacf4",
    },


    applicationResultTitleContainer: {
        marginTop: "50px",
        textAlign: "center",
    },
    applicationResultTitle: {
        fontWeight: "bolder",
        color: "red",
    },
    applicationResultTitleIcon: {
        marginTop: "10px",
        color: "red",
    },

    applicationResultMessageContainer: {
        margin: "35px 0 20px",
        width: "100%",
        textAlign: "center",
    },
    titleImgHeight: {
        height: "25px",
    },
    iframePosition: {
        textAlign: "center",
    },
    displayNone: {
        display: "none",
    },
    underlineStyle: {
        textDecorationColor: "#fff389",
        textDecorationLine: "underline",
        textDecorationThickness: "3px",
        "-webkit-text-decoration": "underline 3px #fff389",
    },

});


export default style;
