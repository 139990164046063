import React, { useState, Dispatch, useEffect } from "react";
import clsx from "clsx";
import style from "./style";
import {
    makeStyles,
    Grid,
    Typography,
    Link,
} from '@material-ui/core';

const cssInCode = makeStyles(style);

const NotSupport: React.FC = () => {
    const classes = cssInCode();

    return (
        <div>
            < div className={classes.root} >
                <div className={classes.bg} />
                <Grid container className={classes.titleGrid}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h4" component="span" className={classes.title}>
                            このブラウザは
                    </Typography>
                        <Typography variant="h4" component="span" className={classes.title}>
                            現在サポートされていません
                    </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.sentenseGrid}>
                    <Grid item xs={12} sm={12} md={12} className={classes.sentense}>
                        <Typography>
                            サポートされているブラウザに切り替えてご利用ください。
                    </Typography>
                    </Grid>
                </Grid>
            </div >

            <Grid container className={classes.whiteFrame}>
                <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.w100}>
                        <Typography className={clsx(classes.recommendedEnvironmentMB)}>OS・ブラウザ</Typography>
                        <Typography className={classes.mZero}>Windows 8.1以上</Typography>
                        <ul className={classes.mZero}>
                            <li>Google Chrome最新版</li>
                            <li>Microsoft Edge最新版（Windows10の場合）</li>
                        </ul>
                        <Typography className={classes.recommendedEnvironmentMT}>macOS10.15以上</Typography>
                        <ul className={classes.mZero}>
                            <li>Safari 最新版</li>
                            <li>Google Chrome最新版</li>
                        </ul>
                    </div>
                </Grid>
            </Grid>


        </div>
    );
};

export default NotSupport;