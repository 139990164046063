import React, { Dispatch, useEffect, useState } from "react";
import {
    makeStyles,
    Grid,
    Typography,
    Hidden,
} from '@material-ui/core';
import clsx from "clsx";
import NttSiteViewPC from "./NttSiteViewPC";
import NttSiteViewMobile from "./NttSiteViewMobile";

const cssInCode = makeStyles(theme => ({
    root: {

    },
    form: {
        opacity: 0,
    },
    w100: {
        width: "100%",
    },
    marginL: {
        marginLeft: "10px",
    },
    nowrap: {
        display: "inline-block",
    },
}));

type Props = {
    url: string;
    hash: string;
    scrollToTop?: Function;
}
const NttSiteView: React.FCX<Props> = (props) => {
    const classes = cssInCode();

    if (props.scrollToTop) {
        props.scrollToTop(true);
    }

    useEffect(() => {
        if (!props?.url) {
            return;
        }

        setTimeout(() => {
            OpenNttWindow();
        }, 2 * 1000);//遷移待ち時間
    }, [props?.url]);

    function OpenNttWindow() {
        const btn = document.getElementById("nttSiteSubmit");
        btn?.click();
    }

    function ReRedirectForm() {
        if (!props?.url) {
            return (<></>)
        }
        return (<>
            { /* NTT DATA のボンクラサイトは文字コードが古すぎてWEBからリクエストできない  */}
            <form
                className={classes.form}
                method="GET"
                action={`/nttSite.html`}
                acceptCharset="shift_jis"
            >
                <input id="nttSiteSubmit" type="submit" value="送信" />
                <input type="hidden" name="url" value={encodeURIComponent(props?.url)} />
                <input type="hidden" name="hash" value={encodeURIComponent(props?.hash)} />
            </form>
        </>);
    }

    return (
        <div className={classes.w100}>
            <Hidden smDown implementation="css" >
                <NttSiteViewPC className={classes.w100} />
            </Hidden>

            <Hidden mdUp implementation="css">
                <NttSiteViewMobile />
            </Hidden>

            <ReRedirectForm />
        </div>)
}

export default NttSiteView;