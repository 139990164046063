import React, { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    AppBar,
    Toolbar,
    SvgIcon,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAutocomplete from "mui-autocomplete-suggestions";

import {
    mdiSync
} from '@mdi/js';
import logo from '../../assets/logo.png';

import { IRootState } from "../../store/rootModel";
import { IDrawer } from "../../store/SideDrawer/model";
import { updateDrawer } from "../../store/SideDrawer/action";
import { ITitle } from "../../store/Layout/model";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
const cssInCode = makeStyles(style);

type Props = {

}
const Titlebar: React.FCX<Props> = (prop) => {
    const classes = cssInCode();
    const dispatch: Dispatch<any> = useDispatch();
    const overlay = useSelector((x: IRootState) => x.overlay);

    function MakeNavSvgIcon(path: string | undefined) {
        if (!path) return null;
        return (
            <SvgIcon className={classes.navIcon} ><path d={path} /></SvgIcon>
        );
    }

    const [value, setValue] = useState("");
    return (
        <div className={clsx(classes.root, prop.className)}>
            <AppBar
                position="fixed"
                color="default"
                className={clsx(classes.appBar)}
            >
                <Toolbar className={clsx(classes.toolbar)}>
                    <a
                        href="https://www.saibugas.co.jp/"
                        rel="noreferrer" target="_blank"
                    >
                        <img src={logo} className={classes.logo} />
                    </a>
                    <div className={clsx(classes.flexGrow, classes.mdUpHidden)} />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Titlebar;