import React, { useState, useEffect, Dispatch } from "react";
import { useForm, FormProvider } from 'react-hook-form' //useFormを使用するためインポート
import {
    makeStyles,
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import Telephone from '../../../components/Telephone';
import FullName from '../../../components/FullName';
import CustomerNumber from '../../../components/CustomerNumber';

import { ContractInfomationProps, NttProps } from "../ContractInfomationProps";
import StyledButton from "../../../../../components/Button/StyledButton";

/** css in js(ts)  */
import clsx from "clsx";
import style from "../../../PC/style";
import localStyle from "./ContractorInquiryStyle";
const cssInCode = makeStyles(style);
const lokcalCssInCode = makeStyles(localStyle);

//index.tsxで設定されている
type Props = {
    Next: (contact: ContractInfomationProps, nttSate: NttProps) => void;
    id: string | null | undefined;
    address: string | null | undefined;
    InquiryAsync: (data: ContractInfomationProps) => Promise<void>;
    radioValue: string;
    clickAgreementRadio: (value: string) => void;
    oksmLabel: {
        oksmNo: string;
        oksmNoPlaceholder: string;
        validation: string;
        oksmName: string;
        noRequiredValidation: string;
        nameRequiredValidation: string;
    };
    href: {
        OksmNoConfirmation: string;
    };
    isKiykshaNotFoundError: boolean;
    contractInquiryBtnLabel: string;
};


const ContractorInquiryView: React.FCX<Props> = (props) => {
    const classes = cssInCode();
    const localClasses = lokcalCssInCode();

    const methods = useForm<ContractInfomationProps>();
    const {
        register,
        handleSubmit,
        errors,
        reset,
        getValues,
        setValue
    } = methods;

    return (<FormProvider {...methods}>
        <form onSubmit={handleSubmit(async (data) => await props.InquiryAsync(data))}>

            {/* 説明 */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={localClasses.pcContractInquiryInformationSentence}>
                    <Typography variant="body1" component="p" className={clsx(localClasses.bolder, classes.underlineStyle)}>
                        <CheckIcon className={localClasses.pcContractInquiryInformationCheckIcon} />
                        以下のご契約情報をご入力ください。
                    </Typography>
                    <Typography variant="body1" component="p" className={localClasses.pcContractInquiryInformationCheckSentence}>
                        <CheckIcon className={localClasses.pcContractInquiryInformationCheckIcon} />
                        西部ガスの都市ガス、電気両方をご契約いただいているお客さまは、両方の支払方法が変更されます。
                    </Typography>
                    <Typography variant="body1" component="p" className={localClasses.pcContractInquiryInformationCheckSentence}>
                        <CheckIcon className={localClasses.pcContractInquiryInformationCheckIcon} />
                        お手元に「ガスご使用量のおしらせ（検針票）」等をご準備ください。
                    </Typography>
                </Grid>
            </Grid>

            <div className={clsx(localClasses.pcContractInquiryErrorMessage, props.isKiykshaNotFoundError ? localClasses.display : localClasses.displayNone)}>
                <Grid container>
                    <Grid item className={localClasses.pcContractInquiryInformationSentence}>
                        <Typography variant="body2" component="p">
                            入力いただいたお客さま情報が見つかりません。
                        </Typography >
                        <Typography variant="body2" component="p">
                            ご入力内容に誤りがないかお確かめのうえ、画面下部の再入力ボタンからご入力ください。
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            {/* 入力欄 */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className={classes.pcInputTitle} variant="h6">
                        ご契約情報の入力
                    </Typography>
                </Grid>
                {/* ご契約内容 横並びにしたい*/}
                <Grid container className={classes.pcInputContainer}>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className={localClasses.pcContractorRadioLabelMT}>
                            <Typography component="span" className={classes.pcInputLabel}>
                                入力いただくご契約
                                </Typography>
                            <Typography component="span" className={classes.requiredMark}>
                                必須
                                </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7}>
                        <RadioGroup
                            aria-label="ContractDetails"
                            name="ContractDetails"
                            value={props.radioValue}
                            onChange={e => props.clickAgreementRadio(e.target.value)}
                            row
                        >
                            <FormControlLabel
                                control={<Radio classes={{ root: localClasses.radio, checked: localClasses.checked }} />}
                                label="都市ガス"
                                value="gas"
                                className={clsx(classes.pcInputLabel, localClasses.radioSpan)}
                                disabled={props.isKiykshaNotFoundError}
                            />
                            <FormControlLabel
                                control={<Radio classes={{ root: localClasses.radio, checked: localClasses.checked }} />}
                                label="電気"
                                value="electricity"
                                className={clsx(classes.pcInputLabel, localClasses.radioSpan)}
                                disabled={props.isKiykshaNotFoundError}
                            />
                        </RadioGroup>
                    </Grid>

                </Grid>

                <Grid container className={classes.borderStyle}></Grid>

                <Grid container className={localClasses.pcContractorInquiryInputContainer}>
                    {/* お客さま番号 */}
                    <Grid item xs={12} sm={12} md={5} className={localClasses.pcCustomerNumberLabelMT}>
                        <Typography component="span" className={classes.pcInputLabel}>
                            {props.oksmLabel.oksmNo}
                        </Typography>
                        <div className={localClasses.pcLabelDisplay}>
                            <Typography component="span" variant="caption" className={clsx(classes.pcInputLabel, props.oksmLabel.oksmName === "電気" && classes.displayNone)}
                            >
                                （料金お問合せ番号）
                            </Typography>
                            <Typography component="span" className={classes.requiredMark}>
                                必須
                        </Typography>
                        </div>
                        <Typography component="p" variant="caption" className={localClasses.oksmNoConfirmationLinkSentence}>
                            ※西部ガスのお客さま番号の確認方法は
                            <Link
                                href={props.href.OksmNoConfirmation} rel="noreferrer" target="_blank"
                                className={clsx(classes.linkColor, localClasses.pcLabelDisplay)} underline="hover">
                                こちら
                            </Link>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} >
                        <CustomerNumber
                            placeHolder={props.oksmLabel.oksmNoPlaceholder}
                            validation={props.oksmLabel.validation}
                            requiredValidation={props.oksmLabel.noRequiredValidation}
                            contract={props.radioValue}
                            disabled={props.isKiykshaNotFoundError}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.borderStyle}></Grid>

                {/* 都市ガスのご契約者さま名 */}
                <Grid container className={localClasses.pcContractorInquiryInputContainer}>
                    {/* ラベル */}
                    <Grid item xs={12} sm={12} md={5} className={localClasses.pcContractorInquiryLabelMT}>
                        <Typography component="span" className={classes.pcInputLabel}>
                            {props.oksmLabel.oksmName}のご契約者さま名
                        </Typography>
                        <Typography component="span" className={classes.requiredMark}>
                            必須
                        </Typography>

                        <Typography
                            variant="caption"
                            component="p"
                            className={clsx(localClasses.colorGray, localClasses.pcLabelDisplay, localClasses.w100)}
                        >
                            ※カタカナで入力してください
                        </Typography>
                    </Grid>
                    {/* 入力欄 */}
                    <Grid item xs={12} sm={12} md={7}>
                        <FullName
                            requiredValidation={props.oksmLabel.nameRequiredValidation}
                            disabled={props.isKiykshaNotFoundError}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.borderStyle}></Grid>

                {/* 連絡先電話番号 */}
                <Grid container className={localClasses.pcContractorInquiryInputContainer}>
                    {/* ラベル */}
                    <Grid item xs={12} sm={12} md={5} className={localClasses.pcContractorInquiryLabelMT}>
                        <Typography component="span" className={classes.pcInputLabel}>連絡先電話番号</Typography>
                        <Typography component="span" className={classes.requiredMark}>必須</Typography>
                    </Grid>

                    {/* 入力欄 */}
                    <Grid item xs={12} sm={12} md={7}>
                        <Grid container>
                            <Telephone disabled={props.isKiykshaNotFoundError} />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            {/* 確認ボタン */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.pcBtnPosition}>
                    <StyledButton
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={localClasses.pcContractInquiryBtn}
                    >
                        {props.contractInquiryBtnLabel}
                    </StyledButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={localClasses.moveNavigationGrid}>
                    <Typography className={localClasses.colorGray}>お客さまのご契約の確認が完了すると、外部サイトへ遷移します</Typography>
                </Grid>
            </Grid>
        </form>

    </FormProvider>);
};

export default ContractorInquiryView;