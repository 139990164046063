import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({
    root: {
        padding: 10,
        marginBottom: "5rem",
        marginTop: "1rem",
    },
    //初期設定等
    backColorWhite: {
        backgroundColor: "white",
        margin: "10px",
        padding: "26px 12px",
        borderRadius: "10px",
        maxWidth: "920px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    mZero: {
        marginTop: "0px",
        marginBottom: "0px",
    },
    positionCenter: {
        width: "100%",
        textAlign: "center",
    },
    pPosition: {
        marginTop: "0px",
    },
    titleTop: {
        marginTop: "10px",
    },
    bolder: {
        fontWeight: "bolder",
    },
    //見出し
    titleLine: {
        backgroundColor: "transparent",
        borderLeft: "solid 6px #1cacf4",
    },
    titleLineSpace: {
        paddingLeft: "15px",
    },
    titleSpace: {
        marginTop: "-20px",
        marginBottom: "0px",
        paddingLeft: "10px",
    },
    //詳しい画面案内用
    shriBackGroundColor: {
        backgroundColor: "#27c571",
        borderRadius: "10px",
    },
    oksmBackGroundColor: {
        backgroundColor: "#26bedd",
        borderRadius: "10px",
    },

    sampleImgSize: {
        height: "99px",
    },
    imgPosition: {
        marginTop: "10px",
        marginLeft: "20px",
    },
    imgSpace: {
        marginBottom: "20px",
    },
    FinancialBanner: {
        width: "100%",
        padding: "5px 20px 0 0",
    },
    OksmNoBanner: {
        width: "100%",
        padding: "5px 0 0 20px",
    },
    bannerStyle: {
        width: "100%",
        marginTop: "15px",
    },
    fontWhiteColor: {
        color: "white",
    },
    fontYubinColor: {
        color: "silver"
    },
    iphone5Space: {
        paddingLeft: "10px",
    },
    iphone5SSize: {
        //  fontSize: "14.8px", 
        // 二行に収めたいihpone5Sでのぎりぎりサイズ
        // これをconstで当てられる？？？？？
    },
    newBtnSampleImg: {
        height: "115px",
        width: "100%",
    },
    shriPastelColor: {
        backgroundColor: "#ebfcf3",
    },
    oksmPastelColor: {
        backgroundColor: "#f1ffff",
    },
    borderRadiusPoint: { //片側だけ丸く
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
    },
    unknowLine: {
        marginLeft: "-3px",
    },
    shriStyle: { //三角のような
        borderStyle: "solid",
        // borderWidth: "0px 0 124px 30px",
        borderColor: "transparent transparent #27c571 transparent",
    },
    oksmStyle: {
        borderStyle: "solid",
        borderWidth: "0px 0 136px 25px",
        borderColor: "transparent transparent #26bedd transparent",
    },
    btnSpaceMargin: {
        marginTop: "10px",
    },
    btnSpacePadding: { //謎スペース解消用
        paddingRight: "3.5px",
        //iphone5S→4.5px
        //その他→3.5px
    },
    //少しかくかくするけどこれでもぎりぎり外枠出る
    //shri
    firstBorderSet1: {
        borderTop: "solid 3px #27c571",
        borderLeft: "solid 3px #27c571",
        borderBottom: "solid 3px #27c571",
    },
    firstBorderSet2: {
        borderTop: "solid 3px #27c571"
    },
    //oksm
    secondBorderSet1: {
        borderTop: "solid 3px #26bedd",
        borderLeft: "solid 3px #26bedd",
        borderBottom: "solid 3px #26bedd",
    },
    secondBorderSet2: {
        borderTop: "solid 3px #26bedd"
    },
    //外側だけ ここじゃできない？ F12ならできた いったん放置
    shriBorder: {
        bolder: "solid !important",
    },
    oksmBorder: {
        bolder: "solid 5px #27c571",
    },
    //iphone5s
    iphone5SPosition: {
        marginLeft: "-11px",
    },
    //ご案内事項
    pcScrollFrame: {
        overflow: "auto",
        border: "3px solid lightgray",
        height: "300px",
        width: "100%",
        borderRadius: "10px",
        padding: "10px",
    },
    scrollFrame: {
        overflow: "auto",
        border: "3px solid lightgray",
        height: "250px",
        width: "100%",
        borderRadius: "10px",
        padding: "10px 10px 10px 0",
    },
    scrollSpace: {
        width: "100%",
    },
    circleStyle: {
        color: "#1ebaf5",
    },
    borderLine: {
        borderTop: "solid 1px gray",
        width: "100%",
        paddingTop: "10px",
    },
    scrollTitle: {
        padding: "10px 0 0 15px",
        fontWeight: "bolder",
    },
    pcScrollTitle: {
        padding: "10px 0 0 10px",
    },
    recommendedEnvironmentGrid: {
        paddingLeft: "18px",
    },
    titleFont: {
        fontWeight: "bold",
        fontSize: "17px",
    },
    buttonPosition: {
        marginBottom: "100px",
        width: "100%",
        marginLeft: "70px",
        marginRight: "70px",
        textAlign: "center",
    },
    buttonStyle: {
        padding: "10px",
        width: "90%",
        marginTop: "20px",
        maxWidth: "400px",
    },
    descriptionGrid: {
        margin: "10px 0",
    },
    pcDescriptionGrid: {
        margin: "25px 0",
    },
    titleM: {
        margin: "40px 0 8px 0",
    },
    //PC追加タイトル
    subTitleSpace: {
        margin: "3px 0 0 6px",
    },
    mRMinus: {
        marginRight: "-50px",
    },
    mRPlus: {
        paddingRight: "50px",
    },
    rightMessageSpace: {
        paddingLeft: "3px",
    },
    fontSize: {
        fontSize: "12px",
    },
    testBtnSpaceA: {
        marginLeft: "-100px",
    },
    testGreenWideA: {
        marginLeft: "50px",
    },
    testHrefTest: {
        marginLeft: "22px",
    },
    testHrefPosition: {
        marginLeft: "-8px",
    },
    testImgPosition: {
        marginLeft: "30px",
    },
    testTitleSpace: {
        marginLeft: "7px",
    },
    testScrollWidth: {
        padding: "5px",
    },
    testBtnWidth: {
        paddingRight: "10px",
        paddingLeft: "10px",
        height: "125px",
    },
    test: {
        paddingRight: "10px",
    },
    pcBackColorWhite: {
        backgroundColor: "white",
        marginTop: "40px",
        marginBottom: "80px",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "26px 20px 60px 20px",
        borderRadius: "10px",
        width: "70%",
        maxWidth: "1245px",
    },
    pcButtonPosition: {
        marginBottom: "100px",
        width: "100%",
        marginLeft: "370px",
        marginRight: "370px",
        textAlign: "center",
    },
    pcMessagePoint: {
        textAlign: "right",
        paddingRight: "20px",
    },
    pcImgPosition: {
        marginTop: "10px",
        marginLeft: "8px",
    },
    pcFontSize: {
        fontSize: "15px",
    },
    pcButtonStyle: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "230px",
    },
    pcTitleLineSpace: {
        paddingLeft: "15px",
    },
    pcShriStyle: { //三角のような
        borderStyle: "solid",
        borderWidth: "0px 0 136px 25px",
        borderColor: "transparent transparent #27c571 transparent",
    },
    pcShriBackGroundColor: {
        backgroundColor: "#27c571",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
    },
    pcOksmBackGroundColor: {
        backgroundColor: "#26bedd",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
    },
    pcImgSpace: {
        marginTop: "15px",
        marginLeft: "5px",
    },
    agreeCheckBox: {
        textAlign: "center",
    },
    /*  liStyle: { //左にスペース開けられる
         paddingLeft: "1em",
         textIndent: "-1.5em",
     },
     markerStyle: {
         '::marker-root': {
             color: "#1cacf4",
             fontSize: "1.5em",
         },
     },
     markerNon: {
         visibility: "hidden",
     },
     ul: {
         'li:: marker': {
             color: "red",
             fontSize: "1.5em",
         }
     }, */
    colorRed: {
        color: "red",
    },
    colorGray: {
        color: "dimgray",
    },
    dividerPL: {
        paddingLeft: "10px",
    },
    dividerW100: {
        width: "100%",
        margin: "0 auto",
    },
    recommendedEnvironmentDiv: {
        paddingLeft: "13px",
        marginBottom: "15px",
    },
    recommendedEnvironmentMT: {
        margin: "10px 0 0 0",
    },
    informationLiMB: {
        margin: "0 0 10px 0",
    },
    recommendedEnvironmentMB: {
        margin: "0 0 8px 0",
    },
    pcConfirmationMessageM: {
        margin: "35px 0 15px 0",
    },
    confirmationMessageM: {
        margin: "30px 0 20px 0",
    },
    messageSpace: {
        margin: "4px 0",
    },
    checkbox: {
        '&$checked': {
            color: '#1cacf4'
        }
    },
    checked: {},
    btn: {
        padding: "10px",
        width: "95%",
        marginTop: "20px",
        minWidth: "205px",
        color: "white",
        backgroundColor: "#1cacf4",
        // fontWeight: "bolder",
    },
    pcBtn: {
        padding: "10px",
        width: "20%",
        marginTop: "20px",
        minWidth: "205px",
        color: "white",
        backgroundColor: "#1cacf4",
        // fontWeight: "bolder",
    },
    linkColor: {
        color: "blue",
    },
    underLineStyle: {
        // textDecoration: "underline 3px #fff389",
        textDecorationColor: "#fff389",
        textDecorationLine: "underline",
        textDecorationThickness: "3px",
        "-webkit-text-decoration": "underline 3px #fff389",
    }
});

export default style;