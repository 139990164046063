import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transparent",
        padding: 10,
        minHeight: "100%",
        marginBottom: "5rem",
        marginTop: "1rem",
    },
    gridWhite: {
        backgroundColor: "white",
        padding: "26px 12px 60px 12px",
        borderRadius: "10px",
        maxWidth: "920px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    message: {
        marginTop: "20px",
        padding: "0 20px",
    },
    titleBar: {
        backgroundColor: "transparent", /*背景透明に*/
        borderLeft: "solid 6px #1cacf4",
        paddingLeft: "15px",
    },
    bolder: {
        fontWeight: "bolder",
    },
    messageSpace: {
        marginBottom: "10px",
    },


    pcGridWhite: {
        backgroundColor: "white",
        padding: "26px 12px 60px 12px",
        borderRadius: "10px",
        marginTop: "20px",
        width: "70%",
        maxWidth: "920px",
        marginRight: "auto",
        marginLeft: "auto",
    },


});


export default style;
