import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({

    pcAuthenticationErrorViewMessageContainer: {
        marginTop: "35px",
        width: "100%",
    },

    pcAuthenticationErrorViewMessageMAuto: {
        margin: "0 auto",
    },

    pcAuthenticationErrorViewMessageMLSpace: {
        marginLeft: "15px",
    },
    pcAuthenticationErrorViewMessageFrame: {
        backgroundColor: "whitesmoke",
        padding: "10px 25px",
        margin: "10px auto",
        width: "460px",
    },
    pcAuthenticationErrorViewBolder: {
        fontWeight: "bolder",
    },
    pcAuthenticationErrorViewTelSentense: {
        margin: "30px 0",
        textAlign: "center"
    },
    pcAuthenticationErrorViewCenter: {
        textAlign: "center",
    },
    pcAuthenticationErrorFrame: {
        border: "2px solid lightgray",
        borderRadius: "4px",
        margin: "0 auto 10px auto",
        textAlign: "center",
        padding: "12px",
        width: "460px",
    },
    pcAuthenticationErrorReceptionTime: {
        color: "gray",
    },
    pcAuthenticationErrorPhoneColor: {
        color: "#1cacf4",
        fontWeight: "bolder",

    },
    oksmServiceTel: {
        fontWeight: "bolder",
        marginBottom: "15px",
        textAlign: "center"
    },
    oksmServiceTelCenter: {
        margin: "0 auto"
    },
    pcTelImgHeight: {
        height: "105px",
    },
    pcTelImgCenter: {
        textAlign: "center",
    },


});


export default style;