import React, { Dispatch, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector, useDispatch } from "react-redux";
import Layout from './views/Layout';
import AppRoute from './AppRoute';
import { IRootState } from './store/rootModel';
import { SnackbarProvider } from 'notistack';
import { isMobile } from "react-device-detect";
import "./core/extensions/EnumerableExtension";
import "./core/extensions/ObjectExtension";
import "./core/extensions/SnackbarExtension";
import "./core/extensions/StringExttension";

import {
    makeStyles,
    CircularProgress,
    createMuiTheme,
    CssBaseline,
} from "@material-ui/core";

/** css in js(ts)  */
const cssInCode = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    error: {
        backgroundColor: "#f5c7c0",
        color: "#5d5b5b",
    },
}));

const App: React.FC = () => {
    const css = cssInCode();
    const overlay = useSelector((x: IRootState) => x.overlay);
    const customTheme = useSelector((x: IRootState) => x.customTheme);
    const customPallete = useSelector((x: IRootState) => x.customPalette);

    const overrideTheme = createMuiTheme({
        typography: {
            "fontFamily": `"Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif"`,
        },
        palette: {
            type: customTheme.mode,
            primary: customPallete.primary,
            secondary: customPallete.secondary,
            background: customPallete.background ?? (customTheme.mode === "dark" ?
                { default: "#303030", paper: "#424242" } : { default: "#fafafa", paper: "#fff" }
            )
        },
        overrides: {
            MuiAppBar: {
                root: {
                    backgroundColor: customPallete.appBar
                },
                colorDefault: {
                    backgroundColor: customPallete.appBar
                },
                colorPrimary: {
                    backgroundColor: customPallete.appBar
                }
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: isMobile ? "rgba(1,159,232,0.9)" : "rgba(0,0,0,0.8)",
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                android9dot6Tablet: 1200,
                lg: 1280,
                xl: 1920,
            }
        }
    });

    return (
        <ThemeProvider theme={overrideTheme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={1}
                classes={{
                    variantError: css.error,
                }}
            >
                <div id="wrapper">
                    <Layout>
                        <AppRoute />
                    </Layout>
                </div>
                <Backdrop className={css.backdrop} open={overlay.openWaitingSite} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
export default App;
