import React, { Dispatch } from "react";
import {
    Hidden,
} from '@material-ui/core';
import { MailAddressProps } from "./../../MailAddressProps";

import PCindex from "./PC/MailInputView";
import Mobileindex from "./MailInputView";
import { useForm } from "react-hook-form";

//htmlの属性として利用する場合はこのような形で宣言する
type Props = {
    Next: Function;
    State: MailAddressProps | undefined;
    SetState: Dispatch<React.SetStateAction<MailAddressProps | undefined>>;

}

const MailInputView: React.FCX<Props> = (props) => {

    const methods = useForm<MailAddressProps>();
    const {
        register: register,//input/select の Ref とバリデーションルールを React Hook Form に登録 (register) することができます。
        handleSubmit: submit,//バリデーションに成功するとフォームデータを返します。
        errors: errors,//各inputのエラー、エラーメッセージが含まれています。
        reset: reset,//フィールド値とエラーをデフォルト値でリセットします。
        getValues: getValues,
        setValue: setValue,
    } = methods;

    function Next(data: MailAddressProps) {
        reset(data);
        props.SetState(data);//useStateで取ったデータをpropsにセット
        props.Next();//次へ進む
    }

    return (<>
        <Hidden smDown implementation="css">
            <PCindex
                Next={(data) => Next(data)}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                Next={(data) => Next(data)}
            />
        </Hidden>
    </>)
};

export default MailInputView;