import React, { useState, useEffect } from "react";
import {
    styled,
    Button,
} from '@material-ui/core';


const StyledButton = styled(Button)({
    //ボタンのhoverスタイル
    "&:hover": {
        backgroundColor: "#1cacf4",
    },
    "&.Mui-disabled": {
        backgroundColor: "lightgray",
        color: "white",
    }
});

export default StyledButton;