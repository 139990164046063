import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import { drawerWidth, drawerMinWidth } from '../../../../../global';

//css
const style: Styles<Theme, {}> = (theme: Theme) => ({



});


export default style;