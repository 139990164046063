import React from "react";
import {
    makeStyles,
    Grid,
    Typography,
    FormControlLabel,
    Divider,
} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import StyledButton from "../../components/Button/StyledButton";

//new
import ListOfFinancialInstitutionsImg from './ListOfFinancialInstitutions.png';
import CustomerNumberConfirmationImg from './CustomerNumberConfirmation.png';
import { HomeProps } from "./HomeProps";

/** css in js(ts)  */
import clsx from "clsx";
import style from "./style";
const cssInCode = makeStyles(style);

const HomeMobile: React.FCX<HomeProps> = (props) => {
    const classes = cssInCode();

    return (
        <div className={classes.root}>
            <Grid>
                <Grid className={clsx(classes.backColorWhite)}>
                    {/*タイトル*/}
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={clsx(classes.titleLine, classes.titleLineSpace)}>
                                <Typography variant="h5" className={clsx(classes.bolder)}>口座振替払いのお申し込み</Typography>
                                <Typography variant="subtitle1" className={clsx(classes.bolder)}>（ガス料金等・電気料金等）</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    {/*説明*/}
                    <Grid container className={classes.descriptionGrid}>
                        <Typography variant="body1" component="span" className={classes.messageSpace}>インターネット上のお手続き画面より、口座振替（新規・変更）をお申し込みいただくことができます。
                            <p className={clsx(classes.bolder, classes.underLineStyle, classes.messageSpace)}>
                                新たにガスをご使用されたお客さまは｢都市ガスご契約手続き完了のおしらせ｣もしくは「ガスご使用量のおしらせ(検針票)」が届いてからお申し込みください。
                            </p>
                            なお、<span className={clsx(classes.bolder, classes.underLineStyle)}>当社の電気契約のみをご契約いただいているお客さまは口座振替のお申し込みはできません。</span></Typography>
                        <Typography variant="body1" className={classes.messageSpace}>以下のご案内を必ずご確認の上、お申し込み画面にお進みください。</Typography>
                    </Grid>
                    {/*口座振替案内*/}
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <a href={props.ListOfFinancialInstitutions}
                                rel="noreferrer" target="_blank"
                            >
                                <img src={ListOfFinancialInstitutionsImg} className={classes.bannerStyle}></img>
                            </a>
                        </Grid>
                    </Grid>

                    {/*申し込み案内*/}
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <a href={props.CustomerNumberConfirmation}
                                rel="noreferrer" target="_blank"
                            >
                                <img src={CustomerNumberConfirmationImg} className={classes.bannerStyle}></img>
                            </a>
                        </Grid>
                    </Grid>
                    {/*案内タイトル*/}
                    <Grid container>
                        <h2 className={clsx(classes.titleLSpace)}>ご案内事項</h2>
                    </Grid>

                    {/*利用規約などスクロール*/}
                    <Grid container className={clsx(classes.scrollFrame)}>


                        {/* 金融機関からのご案内 */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="subtitle1" className={clsx(classes.scrollTitle)} >金融機関からのご案内</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <ul className={clsx(classes.colorGray, classes.bolder, "ul_wrap")}>
                                <p className={clsx(classes.mZero)} > インターネットでのお申し込みは、個人普通口座をお持ちで、キャッシュカードを保有されているお客さまに限らせていただきます。</p>
                                <p className={clsx(classes.mZero)}>法人口座のお客さまは、申し込み用紙によるお手続きをお願いいたします。</p>
                            </ul>
                        </Grid>

                        <Grid container className={classes.dividerPL}>
                            <Divider className={classes.dividerW100}></Divider>
                        </Grid>

                        {/* 西部ガスからのご案内 */}
                        <Grid container>
                            <Typography variant="subtitle1" className={classes.scrollTitle}>西部ガスからのご案内</Typography>
                        </Grid>
                        <Grid container>
                            <ul className={clsx(classes.colorGray, "ul_wrap")}>
                                <li className={classes.informationLiMB}>
                                    ガス料金等・電気料金等の「口座振替払い」は、お客さまのご指定の預貯金口座から毎月自動的にお支払いいただく方法です。
                                    下部に記載の【約定（金融機関宛）】を確約のうえお申し込みください。
                                </li>

                                <li className={clsx(classes.informationLiMB, classes.bolder)}>
                                    都市ガス・電気両方をご契約いただいているお客さまは、両方の支払い方法が口座振替払いに変更されます。
                                </li>

                                <li className={clsx(classes.informationLiMB, classes.bolder)}>
                                    西部ガス（株）とのご契約のうち、電気のみをご契約のお客さまは、口座振替払いをご利用いただけません。
                                </li>

                                <li className={classes.informationLiMB}>
                                    新たに都市ガス・電気のご使用を開始されたお客さまは、都市ガスの開栓日または電気のお申し込み日から2～3日間（土日祝日を除く）は、
                                    インターネットでのお申し込みを承ることができません。
                                </li>

                                <li>
                                    振替口座を変更される場合、またはクレジット払いから変更される場合は、手続きが完了するまでは旧口座での振替、またはクレジットでのお支払いとなります。
                                </li>
                                <p className={classes.informationLiMB}>
                                    手続きが完了次第、新しい口座での振替が開始され、旧口座およびクレジット払いは自動的に解約されます。
                                </p>

                                <li>
                                    お客さまの個人情報は、
                                    <a href={props.PrivacyPolicy} rel="noreferrer" target="_blank" className={classes.linkColor}>個人情報保護方針</a>
                                    に則り、ガス料金等・電気料金等のお支払いに関する業務のほか、
                                    都市ガス・電気の供給及びそれらに付随する業務に利用させていただきます。
                                </li>
                                <p className={classes.informationLiMB}>
                                    ただし、口座情報につきましては、口座振替に関する業務にのみ使用いたします。
                                </p>

                                <li>
                                    口座振替開始のご案内は、「ガス料金等口座振替払い手続き完了のお知らせ」（ハガキ）にてお知らせします。
                                </li>
                                <p className={classes.informationLiMB}>
                                    ただし、前月以前分のお支払いがお済みでない場合には、既にお届けしております払込票でのお支払いをお願いいたします。
                                </p>

                                <li className={classes.informationLiMB}>
                                    お支払いの結果は、翌月の検針時に「ガスご使用のおしらせ（検針票）」の中でお知らせいたします。
                                    ただし、ガスのご使用場所以外のご住所へのお届けを希望される場合は、お申し出によりそのご住所へ
                                    「ガス料金口座振替済みのおしらせ」（ハガキ）を郵送します。
                                </li>

                                <li className={clsx(classes.mZero)}>
                                    ガスのご使用場所以外へ「ガス料金口座振替済みのおしらせ」（ハガキ）の送付をご希望の場合は、西部ガスお客さまサービスセンターまでご連絡ください。
                                </li>
                            </ul>
                        </Grid>

                        <Grid container className={classes.dividerPL}>
                            <Divider className={classes.dividerW100}></Divider>
                        </Grid>

                        {/* 【約定（金融機関宛）】 */}
                        <Grid container>
                            <Typography variant="subtitle1" className={classes.scrollTitle}>【約定（金融機関宛）】</Typography>
                        </Grid>
                        <Grid container>
                            <ul className={clsx(classes.colorGray, "ul_wrap")}>
                                <li className={classes.informationLiMB}>
                                    西部ガスから、私が支払うべきガス料金等の請求書が送付されたときは、私に通知しないで所定の振替日に、
                                    指定預貯金口座から請求書記載の金額を払い出し、西部ガスの指定口座に振り込んでください。
                                </li>

                                <li className={classes.informationLiMB}>
                                    前項の手続きについては当座勘定規定または預貯金規定にかかわらず、
                                    当座小切手の振出し、または、預貯金通帳および預貯金払戻し請求書の提出はいたしません。
                                </li>

                                <li className={classes.informationLiMB}>
                                    指定預貯金残高が振替日において請求書の金額に満たないときは、私に通知されることなく、請求書を返却されても異議ありません。
                                </li>

                                <li className={classes.informationLiMB}>
                                    この口座振替払い契約は、貴行が必要と認めた場合には、私に通知されることなく解約されても異議ありません。
                                </li>

                                <li className={classes.informationLiMB}>この取扱いについて、かりに紛議が生じても貴行にいっさい迷惑をかけません。</li>

                                <p className={clsx(classes.fontYubinColor, classes.mZero)}>※ゆうちょ銀行を除きます。また、ゆうちょ銀行をご指定の場合は、自動払込み規定が適用されます。</p>

                            </ul>
                        </Grid>

                        <Grid container className={classes.dividerPL}>
                            <Divider className={classes.dividerW100}></Divider>
                        </Grid>

                        {/* 【推奨環境】 */}
                        <Grid container>
                            <Typography variant="subtitle1" className={classes.scrollTitle}>【推奨環境】</Typography>
                        </Grid>
                        <Grid container className={classes.recommendedEnvironmentGrid}>
                            <Grid item xs={12} sm={12} md={12} className={classes.colorGray}>
                                <p className={clsx(classes.recommendedEnvironmentMB)}>パソコン</p>

                                <div className={classes.recommendedEnvironmentDiv}>
                                    <p className={clsx(classes.recommendedEnvironmentMB)}>OS・ブラウザ</p>
                                    <p className={classes.mZero}>Windows 8.1以上</p>
                                    <ul className={classes.mZero}>
                                        <li>Google Chrome最新版</li>
                                        <li>Microsoft Edge最新版（Windows10の場合）</li>
                                    </ul>
                                    <p className={classes.recommendedEnvironmentMT}>macOS10.15以上</p>
                                    <ul className={classes.mZero}>
                                        <li>Safari 最新版</li>
                                        <li>Google Chrome最新版</li>
                                    </ul>
                                </div>
                                <p className={clsx(classes.recommendedEnvironmentMB)}>スマートフォン</p>
                                <div className={classes.recommendedEnvironmentDiv}>
                                    <p className={classes.mZero}>iPhone</p>
                                    <ul className={classes.mZero}>
                                        <li>OS：iOS 13.0 以上</li>
                                        <li>ブラウザ：Safari 最新</li>
                                    </ul>
                                    <p className={classes.recommendedEnvironmentMT}>Android</p>
                                    <ul className={classes.mZero}>
                                        <li>OS：Android 7.0 以上</li>
                                        <li>ブラウザ：標準ブラウザ または Chrome最新</li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>


                    {/*確認メッセージ*/}
                    <Grid container className={clsx(classes.positionCenter, classes.confirmationMessageM)}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography component="p" variant="body1" className={classes.messageSpace}>上記のご案内事項をご確認いただき、</Typography>
                            <Typography component="p" variant="body1" className={classes.messageSpace}>「同意して申し込み画面へ」</Typography>
                            <Typography component="p" variant="body1" className={classes.messageSpace}>ボタンを押してください。</Typography>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} className={classes.agreeCheckBox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.agreeCheck}
                                        name="checkedCellPhone"
                                        color="default"
                                        onChange={() => props.onCheckedAgree()}
                                        classes={{ root: classes.checkbox, checked: classes.checked }}
                                    />
                                }
                                label="同意する"

                            />
                        </Grid>
                    </Grid>

                    {/*同意ボタン*/}
                    <Grid container>
                        <Grid item sm={12} md={12} className={clsx(classes.positionCenter)}>
                            <StyledButton
                                className={clsx(classes.btn)}
                                color="primary"
                                variant="contained"
                                onClick={() => props.onAgree()}
                                disabled={!props.agreeCheck}
                            >
                                同意して申し込み画面へ
                            </StyledButton>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </div >
    );
};

export default HomeMobile;