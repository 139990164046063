import React, { Dispatch } from "react";
import { useDispatch } from "react-redux";
import {
    Hidden,
} from '@material-ui/core';
import { MailAddressProps } from "./../../MailAddressProps";
import { useToast } from "../../../../core/extensions/SnackbarExtension";
import { WaitSite } from "../../../../store/Overlay/action";
import { Request } from "../../../../api/Authorize/Request";
import { Response } from "../../../../api/Authorize/Response";

import PCindex from "./PC/MailVerificationView";
import Mobileindex from "./MailVerificationView";

type Props = {
    MailState: MailAddressProps;
    SetState: Dispatch<React.SetStateAction<MailAddressProps | undefined>>;
    Next: Function;
    Back: Function;
    scrollToTop?: Function;
}

const MailVerificationView: React.FCX<Props> = (props) => {

    //くるくる
    const dispatch: Dispatch<any> = useDispatch();
    //トースト
    const toast = useToast();

    if (props.scrollToTop) {
        props.scrollToTop(true);
    }

    /**
     * 認証　step1で入力された内容を受け取る(data)
     */
    async function AuthorizeAsync() {
        //データのアップロードするとき
        /* POSTも、GETと同じくユーザからのデータをやり取りする技術です。
        違うのはURLを使わないという点で、HTMLのFORMやJavaScriptを使ってウェブサーバに送信します。
        URLに出したくないデータなどはPOSTが適しています。
        GETは画面遷移
        POSTはapiとか
        */
        const method = "POST";

        //通信（request/respone）形式を指定している
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        };

        //宛先のメールアドレスをエンコード(暗号化)
        const to = encodeURI(props.MailState.address);
        const request: Request = { address: to };

        // くるくる出す
        dispatch(WaitSite(true));

        try {
            //fetch():リクエストやレスポンスといった HTTP のパイプラインを構成する要素を操作できるようになります。非同期のネットワーク通信を簡単にわかりやすく記述できるようになります。
            //JSONデータのアップロード(fetch()を使ってJSONエンコードしたデータをPOSTします)
            //ここでAuthorize
            const res = await fetch(`api/Authorize`, {
                method: method,
                headers: headers,
                credentials: 'include',//自分と同じサーバーからのリクエストだけを受け付ける　認証情報付きのリクエストの送信 credentials: 'include' を init オブジェクトに追加して fetch() メソッドに渡します。
                body: JSON.stringify(request)//json
            });

            // レスポンスで番号返すのは暫定で、本来はサーバーに記録して照合する
            const result: Response = await res.json();

            //異常(status200が正常)
            if (res.status !== 200) {
                //トースターの表示内容
                toast.Error(`${result.errorMessage}：${res.statusText}(${res.status})`);
                return;//異常はここで終わる
            }

            // setState と同じ扱いで、引数のオブジェクトに値追加で react-hooks-form のステートを更新する
            const newValue = { ...props.MailState, key: result.id as any, timeoutSec: result.timeout ?? 30 };//dataのkeyにレスポンスされたidを入れる

            props.SetState(newValue);
            // 次へ
            props.Next();
        }
        //異常
        catch (error) {
            console.error(error);
            toast.Error(error.toString());
        }
        //tryCatch後に実行される
        finally {
            // くるくる消す
            dispatch(WaitSite(false));
        }
    }

    return (<>
        <Hidden smDown implementation="css">
            <PCindex
                MailState={props.MailState}
                Back={props.Back}
                AuthorizeAsync={() => AuthorizeAsync()}
            />
        </Hidden>

        <Hidden mdUp implementation="css">
            <Mobileindex
                MailState={props.MailState}
                Back={props.Back}
                AuthorizeAsync={() => AuthorizeAsync()}
            />
        </Hidden>
    </>)
};

export default MailVerificationView;